
import { ethers, Contract, BigNumber } from "ethers"
import { Web3Provider } from "@ethersproject/providers"
import BigNumberDecimal from "bignumber.js"
import { Connector } from "@web3-react/types"

import { Units, parseUnits, ZERO, ONE_ETHER, APPROVAL_THRESHOLD, MAX_UINT, isBaseToken, sortTokens, formatUnits } from "./web2Utils"

import { frc20Abi, frc759Abi, factoryAbi, routerAbi, pairAbi, vaultAbi } from "./abi"
import networks, { Network, nullNetwork } from "./networks"
import { getError } from "./errors"
import { Token, TokenOnNetwork, ZERO_ADDR } from "./tokens"
import { MetaMask } from "@web3-react/metamask"


interface Connection {
  provider: any,
  accounts: string[],
  chainId: number,
  connected: boolean,
  reconnect: (() => void)
}

interface Web3 {
  ethersSigner: ethers.providers.JsonRpcSigner
}

interface Contracts {
  routerAddr: string,
  factoryAddr: string,
  wfsnAddr: string,
  vaultAddr: string
}

interface TxConfig {
  slippage: string,
  gasPrice: string,
  deadline: string
}

interface GasFees {
  low: BigNumber,
  medium: BigNumber,
  high: BigNumber
}

interface NetAmounts {
  tokenA: BigNumber,
  tokenB: BigNumber
}

interface Status {
  msg: string,
  code: StatusCode,
  tx?: string
}

interface LPBalance {
  tokenA: TokenOnNetwork,
  tokenB: TokenOnNetwork,
  pairAddr: string,
  fullBal: BigNumber,
  vaultBal: BigNumber
}

interface RemovableLiquidity {
  amountA: BigNumber,
  amountB: BigNumber
}

interface Symbols {
  symbolA: string,
  symbolB: string
}

interface Reserves {
  reserveA: BigNumber,
  reserveB: BigNumber
}

interface Balances {
  balA: BigNumber,
  balB: BigNumber
}

interface PriceImpact {
  actualPrice: BigNumber,
  percentage: string
}

interface TotalBurned {
  liquiditySliceAmountBurned: BigNumber,
  burnedBy: BigNumber
}

enum ExactField {
  From,
  To
}

enum Config {
  Slippage,
  GasPrice,
  Deadline
}

enum Net {
  Min,
  Max
}

enum StatusCode {
  Fail,
  Warn,
  Success
}

enum Term {
  Spot,
  Short,
  Medium,
  Long
}

enum LiquidityType {
  Spot,
  Futures,
  Options
}

enum WalletListOptions {
  WalletConnect,
  MetaMask
}

const nullConnection: Connection = {
  provider: null,
  accounts: [],
  chainId: 0,
  connected: false,
  reconnect: () => {}
}

// const connectWeb3 = async (provider: any, accounts: string[] | undefined, chainId: number | undefined, reconnect: () => Promise<void>) => {
//   if(provider) {
//     let connected = false
//     // const accounts = await provider.request({ method: "eth_requestAccounts" })
//     // const chainId = Number(await provider.request({ method: "eth_chainId" }))
//     if(!chainId) throw UNDEFINED_CHAINID()
//     if(!chainId || !validChain(chainId)) throw UNSUPPORTED_CHAIN(chainId)
//     connected = true
//     const web3Connection = {
//       provider,
//       accounts,
//       chainId,
//       connected,
//       reconnect
//     }
//     return web3Connection
//   } else {
//     throw WEB3_MISSING()
//   }
// }

const getWeb3 = (provider: Web3Provider): Web3 => {
  const ethersSigner: ethers.providers.JsonRpcSigner = provider.getSigner()
  return { ethersSigner }
}

const getBaseBal = async (provider: Web3Provider, accounts: string[]): Promise<BigNumber> => {
  const balWei = await provider.getBalance(accounts[ 0 ])
  return balWei
}

const getNetwork = (chainId: number): Network => {
  for(let i = 0; i < networks.length; i++) {
    if(networks[ i ].chainId === chainId) return networks[ i ]
  }
  return nullNetwork
}

const getTokens = async (): Promise<Token[]> => {
  const tokenListPreLoaded = await fetch("https://api.freemoon.exchange/tokens")
  const tokenListLoaded = await tokenListPreLoaded.json()
  return tokenListLoaded
}

const loadLogos = async () => {
  const tokens = await getTokens()
  const logos = []
  for(let i = 0; i < tokens.length; i++) {
    let img = new Image()
    img.src = `https://api.freemoon.exchange/logos/${ tokens[ i ].logo }`
    logos.push(img)
  }
}

const deductGasFee = (chainId: number, tokenAddr: string, amount: BigNumber): BigNumber => {
  if(tokenAddr === ZERO_ADDR) {
    const { nativeCurrency } = getNetwork(chainId)
    const gasToLeave = parseUnits(String(nativeCurrency.gasToLeave), Units.ether)
    return amount.sub(gasToLeave)
  } else {
    return amount
  }
}

const getGasFees = async (provider: Web3Provider): Promise<GasFees> => {
  const gasPrice = await provider.getGasPrice()
  const lowGasPrice = gasPrice.div(2)
  const highGasPrice = gasPrice.mul(2)
  return { low: lowGasPrice, medium: gasPrice, high: highGasPrice }
}

const hideIdenticalTokens = (chainId: number, tokenAddr: string, unavailable?: string[]): string[] => {
  const { wfsnAddr } = getContractsOnNetwork(chainId)

  let totalUnavailable = []
  if(unavailable && unavailable.length > 0) totalUnavailable = unavailable
  else totalUnavailable = [ tokenAddr ]

  if(tokenAddr === wfsnAddr) totalUnavailable.push(ZERO_ADDR)
  else if(tokenAddr === ZERO_ADDR) totalUnavailable.push(wfsnAddr)

  return totalUnavailable
}

const getTokensOnNetwork = async (liquidityType: LiquidityType, unavailable?: string[]): Promise<TokenOnNetwork[]> => {
  // get all tokens on the current network, that correspond to liquidityType (spot, futures, options)
  const tokens = await getTokens()
  let tokensOnNetwork: TokenOnNetwork[] = []


  // <<< LIQUIDITY TYPE SPOT >>>
  if(liquidityType === LiquidityType.Spot) {
    for(let i = 0; i < tokens.length; i++) {
      if(unavailable && unavailable.includes(tokens[ i ].address)) continue
      tokensOnNetwork.push({
        name: tokens[ i ].name,
        symbol: tokens[ i ].symbol,
        address: tokens[ i ].address,
        decimals: tokens[ i ].decimals.spot,
        logo: `https://api.freemoon.exchange/logos/${ tokens[ i ].logo }`
      })
    }

  // <<< LIQUIDITY TYPE FUTURES >>>
  } else if(liquidityType === LiquidityType.Futures) {
    const symbolPrefix = "TF_"
    const symbolSuffixes = [ "-S", "-M", "-L" ]

    for(let i = 0; i < tokens.length; i++) {
      for(let j = 0; j < tokens[ i ].futures.length; j++) {
        if(unavailable && unavailable.includes(tokens[ i ].futures[ j ])) continue
        if(!tokens[ i ].futures[ j ]) continue
        const name = symbolPrefix + tokens[ i ].symbol + symbolSuffixes[ j ]
        const symbol = tokens[ i ].symbol + symbolSuffixes[ j ]
        const address = tokens[ i ].futures[ j ]

        tokensOnNetwork.push({ 
          name,
          symbol,
          address,
          decimals: tokens[ i ].decimals.futures[ j ],
          logo: `https://api.freemoon.exchange/logos/${ tokens[ i ].logo }`
        })
      }
    }

  // <<< LIQUIDITY TYPE OPTIONS >>>
  } else if(liquidityType === LiquidityType.Options) {
    const namePrefixes = [ "Call ", "Put " ]
    const symbolSuffixes = [ "-CO", "-PO" ]

    for(let i = 0; i < tokens.length; i++) {
      for(let j = 0; j < tokens[ i ].options.length; j++) {
        if(!tokens[ i ].options[ j ]) continue
        if(unavailable && unavailable.includes(tokens[ i ].options[ j ])) continue

        const name = namePrefixes[ j ] + tokens[ i ].name
        const symbol = tokens[ i ].symbol + symbolSuffixes[ j ]
        const address = tokens[ i ].options[ j ]

        tokensOnNetwork.push({
          name,
          symbol,
          address,
          decimals: tokens[ i ].decimals.options[ j ],
          logo: `https://api.freemoon.exchange/logos/${ tokens[ i ].logo }`
        })
      }      
    }
  }

  return tokensOnNetwork
}

const getContractsOnNetwork = (chainId: number): Contracts => {
  let { contracts } = getNetwork(chainId)
  return contracts
}

const getUnavailablePairsForToken = async (provider: Web3Provider, chainId: number, tokenAddr: string): Promise<string[]> => {
  // return required: an array containing all unavailable addresses - ie. addresses with NO liquidity with tokenAddr.

  const checkTypeLiquidity = async (lType: LiquidityType) => {
    const tokenList = await getTokensOnNetwork(lType)

    let addresses = []
    let requests = []

    for(let i = 0; i < tokenList.length; i++) {
      addresses.push(tokenList[ i ].address)
      requests.push(checkIfPairExists(provider, chainId, tokenAddr, tokenList[ i ].address))
    }

    return { addresses, requests }
  }

  const allAddressesAndRequests = await Promise.all([
    checkTypeLiquidity(LiquidityType.Spot),
    checkTypeLiquidity(LiquidityType.Futures),
    checkTypeLiquidity(LiquidityType.Options)
  ])
  let allAddresses = []
  let fullListOfRequestsUnresolved = []

  for(let i = 0; i < allAddressesAndRequests.length; i++) {
    for(let j = 0; j < allAddressesAndRequests[ i ].addresses.length; j++) {
      allAddresses.push(allAddressesAndRequests[ i ].addresses[ j ])
      fullListOfRequestsUnresolved.push(allAddressesAndRequests[ i ].requests[ j ])
    }
  }

  const pairExists = await Promise.all(fullListOfRequestsUnresolved)

  let addressesUnavailable = []

  for(let i = 0; i < pairExists.length; i++) {
    if(!pairExists[ i ]) {
      addressesUnavailable.push(allAddresses[ i ])
    }
  }

  return addressesUnavailable
}

const getSymbols = async (tokenAAddr: string, tokenBAddr: string): Promise<Symbols> => {
  const tokens = await getTokensOnNetwork(LiquidityType.Spot)
  let symbolA = "(Unknown Token)", symbolB = "(Unknown Token)"
  for(let i = 0; i < tokens.length; i++) {
    if(tokens[ i ].address === tokenAAddr) symbolA = tokens[ i ].symbol
    else if(tokens[ i ].address === tokenBAddr) symbolB = tokens[ i ].symbol
  }
  return { symbolA, symbolB }
}

const baseToWrapped = (chainId: number, tokenAAddr: string, tokenBAddr: string): { addrA: string; addrB: string } => {
  const { wfsnAddr } = getContractsOnNetwork(chainId)
  const addrA = tokenAAddr === ZERO_ADDR ? wfsnAddr : tokenAAddr
  const addrB = tokenBAddr === ZERO_ADDR ? wfsnAddr : tokenBAddr
  return { addrA, addrB }
}

const getToken20 = (provider: Web3Provider, tokenAddr: string): Contract => {
  const { ethersSigner } = getWeb3(provider)
  return new Contract(tokenAddr, frc20Abi, ethersSigner)
}

const getToken759 = (provider: Web3Provider, tokenAddr: string): Contract => {
  const { ethersSigner } = getWeb3(provider)
  return new Contract(tokenAddr, frc759Abi, ethersSigner)
}

const getFactory = (provider: Web3Provider, chainId: number): Contract => {
  const { ethersSigner } = getWeb3(provider)
  const { factoryAddr }= getContractsOnNetwork(chainId)
  return new Contract(factoryAddr, factoryAbi, ethersSigner)
}

const getRouter = (provider: Web3Provider, chainId: number): Contract => {
  const { ethersSigner } = getWeb3(provider)
  const { routerAddr } = getContractsOnNetwork(chainId)
  return new Contract(routerAddr, routerAbi, ethersSigner)
}

const getPair = async (provider: Web3Provider, chainId: number, tokenAAddr: string, tokenBAddr: string): Promise<Contract> => {
  const { addrA, addrB } = baseToWrapped(chainId, tokenAAddr, tokenBAddr)
  const { ethersSigner } = getWeb3(provider)
  const factory = getFactory(provider, chainId)
  const pairAddr = await factory.getPair(addrA, addrB)
  return new Contract(pairAddr, pairAbi, ethersSigner)
}

const getPairAddress = async (provider: Web3Provider, chainId: number, tokenAAddr: string, tokenBAddr: string): Promise<string> => {
  const { addrA, addrB } = baseToWrapped(chainId, tokenAAddr, tokenBAddr)
  const factory = getFactory(provider, chainId)
  return await factory.getPair(addrA, addrB)
}

const getVault = (provider: Web3Provider, chainId: number): Contract => {
  const { ethersSigner } = getWeb3(provider)
  const { vaultAddr } = getContractsOnNetwork(chainId)
  return new Contract(vaultAddr, vaultAbi, ethersSigner)
}

const getTokenBal = async (provider: Web3Provider, accounts: string[], tokenAddr: string): Promise<BigNumber> => {
  if(!tokenAddr) return ZERO
  if(isBaseToken(tokenAddr)) return await getBaseBal(provider, accounts)
  const token = getToken20(provider, tokenAddr)
  const tokenBalanceWei = await token.balanceOf(accounts[ 0 ])
  return tokenBalanceWei
}

const getVaultBal = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string, termEnd: string): Promise<BigNumber> => {
  const vault = getVault(provider, chainId)
  if(tokenAAddr === ZERO_ADDR) {
    return await vault.burnedByETH(tokenBAddr, termEnd, accounts[ 0 ])
  } else if(tokenBAddr === ZERO_ADDR) {
    return await vault.burnedByETH(tokenAAddr, termEnd, accounts[ 0 ])
  } else {
    return await vault.burnedBy(tokenAAddr, tokenBAddr, termEnd, accounts[ 0 ])
  }
}

const getPairBalances = async (provider: Web3Provider, chainId: number, tokenAAddr: string, tokenBAddr: string): Promise<{ balA: BigNumber, balB: BigNumber }> => {
  const { addrA, addrB } = baseToWrapped(chainId, tokenAAddr, tokenBAddr)
  const pairAddr = await getPairAddress(provider, chainId, addrA, addrB)
  const tokenA = getToken20(provider, addrA)
  const tokenB = getToken20(provider, addrB)
  const balA = await tokenA.balanceOf(pairAddr)
  const balB = await tokenB.balanceOf(pairAddr)
  return { balA, balB }
}

const getRouterAllowance = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAddr: string): Promise<BigNumber> => {
  if(!tokenAddr) return ZERO
  const { routerAddr } = getContractsOnNetwork(chainId)
  if(isBaseToken(tokenAddr)) return MAX_UINT
  const token = getToken20(provider, tokenAddr)
  const routerAllowanceWei = await token.allowance(accounts[ 0 ], routerAddr)
  return routerAllowanceWei
}

const checkIfNeedsApproval = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAddr: string): Promise<boolean> => {
  const allowance = await getRouterAllowance(provider, chainId, accounts, tokenAddr)
  const needsApproval = allowance.lt(APPROVAL_THRESHOLD)
  return needsApproval
}

const getBurnAllowance = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string): Promise<BigNumber> => {
  const { vaultAddr } = getContractsOnNetwork(chainId)
  const { addrA, addrB } = baseToWrapped(chainId, tokenAAddr, tokenBAddr)
  const pairAddr = await getPairAddress(provider, chainId, addrA, addrB)
  const token = getToken20(provider, pairAddr)
  const burnAllowanceWei = await token.allowance(accounts[ 0 ], vaultAddr)
  return burnAllowanceWei
}

const checkIfBurnNeedsApproval = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string): Promise<boolean> => {
  const allowance = await getBurnAllowance(provider, chainId, accounts, tokenAAddr, tokenBAddr)
  const needsApproval = allowance.lt(APPROVAL_THRESHOLD)
  return needsApproval
}

const checkIfPairNeedsApproval = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string): Promise<boolean> => {
  const pairAddr = await getPairAddress(provider, chainId, tokenAAddr, tokenBAddr)
  if(pairAddr === ZERO_ADDR) return false
  const allowance = await getRouterAllowance(provider, chainId, accounts, pairAddr)
  const needsApproval = allowance.lt(APPROVAL_THRESHOLD)
  return needsApproval
}

const checkIfPairExists = async (provider: Web3Provider, chainId: number, tokenAAddr: string, tokenBAddr: string): Promise<boolean> => {
  const { addrA, addrB } = baseToWrapped(chainId, tokenAAddr, tokenBAddr)
  const factory = getFactory(provider, chainId)
  const pairAddr = await factory.getPair(addrA, addrB)
  return pairAddr !== ZERO_ADDR
}

const getReserves = async (provider: Web3Provider, chainId: number, tokenAAddr: string, tokenBAddr: string): Promise<Reserves> => {
  const pair = await getPair(provider, chainId, tokenAAddr, tokenBAddr)
  if(pair.address === ZERO_ADDR) return { reserveA: ZERO, reserveB: ZERO }
  const reserves = await pair.getReserves()
  const { addrA, addrB } = baseToWrapped(chainId, tokenAAddr, tokenBAddr)
  const { addr0 } = sortTokens(addrA, addrB)
  const reserveA = addrA === addr0 ? reserves[ 0 ] : reserves[ 1 ]
  const reserveB = addrB === addr0 ? reserves[ 0 ] : reserves[ 1 ]
  return { reserveA, reserveB }
}

const quote = async (provider: Web3Provider, chainId: number, amount: BigNumber, tokenAAddr: string, tokenBAddr: string, reserves?: Reserves): Promise<BigNumber> => {
  const router = getRouter(provider, chainId)
  const { reserveA, reserveB } = reserves ? reserves : await getReserves(provider, chainId, tokenAAddr, tokenBAddr)
  return await router.quote(amount, reserveA, reserveB)
}

const calculateNetSlippage = (amount: BigNumber, slippage: string, net: Net): BigNumber => {
  const percentage = new BigNumberDecimal(slippage).div("100")
  const difference = percentage.multipliedBy(amount.toString())
  const differenceWei = BigNumber.from(difference.toFixed(0))

  let netSlippage = ZERO

  if(net === Net.Min) {
    netSlippage = amount.sub(differenceWei)
  } else if (net === Net.Max) {
    netSlippage = amount.add(differenceWei)
  }

  return netSlippage
}

const getLPBalance = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string): Promise<BigNumber> => {
  const pair = await getPair(provider, chainId, tokenAAddr, tokenBAddr)
  if(pair.address === ZERO_ADDR) return ZERO
  const balance = await pair.balanceOf(accounts[ 0 ])
  return balance
}

const loadLPBalances = async (provider: Web3Provider, chainId: number, accounts: string[], liquidityType: LiquidityType): Promise<LPBalance[]> => {
  const { wfsnAddr } = getContractsOnNetwork(chainId)
  const termEnd = await getTermDate(provider, chainId, Term.Long)
  let fullBalPromises = []
  let vaultBalPromises = []
  let pairsChecked: any = []

  if(liquidityType === LiquidityType.Spot) {
    let spot = await getTokensOnNetwork(LiquidityType.Spot)
    
    for(let i = 0; i < spot.length; i++) {
      let tokenA = spot[ i ].address

      for(let j = 0; j < spot.length; j++) {
        let tokenB = spot[ j ].address
        if(tokenA === tokenB) continue
        if(tokenA === wfsnAddr || tokenB === wfsnAddr) continue
        if(pairsChecked.includes(tokenA + tokenB) || pairsChecked.includes(tokenB + tokenA)) continue
        pairsChecked.push(tokenA + tokenB)
        pairsChecked.push(tokenB + tokenA)
        let pairAddr = await getPairAddress(provider, chainId, tokenA, tokenB)
        if(pairAddr !== ZERO_ADDR) {
          let fullBalPromise = getTokenBal(provider, accounts, pairAddr)
          let vaultBalPromise = getVaultBal(provider, chainId, accounts, tokenA, tokenB, termEnd)
          fullBalPromises.push({ tokenA: spot[ i ], tokenB: spot[ j ], pairAddr, balance: fullBalPromise })
          vaultBalPromises.push({ tokenA: spot[ i ], tokenB: spot[ j ], pairAddr, balance: vaultBalPromise })
        }
      }
    }

  } else if(liquidityType === LiquidityType.Futures) {
    let [ spot, futures ] = await Promise.all([ getTokensOnNetwork(LiquidityType.Spot), getTokensOnNetwork(LiquidityType.Futures) ])
    let allTokens = spot.concat(futures)

    for(let i = 0; i < futures.length; i++) {
      let tokenA = futures[ i ].address
      for(let j = 0; j < allTokens.length; j++) {
        let tokenB = allTokens[ j ].address
        if(tokenA === tokenB) continue
        if(tokenA === wfsnAddr || tokenB === wfsnAddr) continue
        if(pairsChecked.includes(tokenA + tokenB) || pairsChecked.includes(tokenB + tokenA)) continue
        pairsChecked.push(tokenA + tokenB)
        pairsChecked.push(tokenB + tokenA)
        let pairAddr = await getPairAddress(provider, chainId, tokenA, tokenB)
        if(pairAddr !== ZERO_ADDR) {
          let fullBalPromise = getTokenBal(provider, accounts, pairAddr)
          let vaultBalPromise = getVaultBal(provider, chainId, accounts, tokenA, tokenB, termEnd)
          fullBalPromises.push({ tokenA: futures[ i ], tokenB: allTokens[ j ], pairAddr, balance: fullBalPromise })
          vaultBalPromises.push({ tokenA: futures[ i ], tokenB: allTokens[ j ], pairAddr, balance: vaultBalPromise })
        }
      }
    }

  } else if(liquidityType === LiquidityType.Options) {
    let [ spot, options ] = await Promise.all([ getTokensOnNetwork(LiquidityType.Spot), getTokensOnNetwork(LiquidityType.Options) ])
    let allTokens = spot.concat(options)

    for(let i = 0; i < options.length; i++) {
      let tokenA = options[ i ].address
      for(let j = 0; j < allTokens.length; j++) {
        let tokenB = allTokens[ j ].address
        if(tokenA === tokenB) continue
        if(tokenA === wfsnAddr || tokenB === wfsnAddr) continue
        if(pairsChecked.includes(tokenA + tokenB) || pairsChecked.includes(tokenB + tokenA)) continue
        pairsChecked.push(tokenA + tokenB)
        pairsChecked.push(tokenB + tokenA)
        let pairAddr = await getPairAddress(provider, chainId, tokenA, tokenB)
        if(pairAddr !== ZERO_ADDR) {
          let fullBalPromise = getTokenBal(provider, accounts, pairAddr)
          let vaultBalPromise = getVaultBal(provider, chainId, accounts, tokenA, tokenB, termEnd)
          fullBalPromises.push({ tokenA: options[ i ], tokenB: allTokens[ j ], pairAddr, balance: fullBalPromise })
          vaultBalPromises.push({ tokenA: options[ i ], tokenB: allTokens[ j ], pairAddr, balance: vaultBalPromise })
        }
      }
    }
  }

  const fullBalances = Promise.all(fullBalPromises.map(fbp => fbp.balance))
  const vaultBalances = Promise.all(vaultBalPromises.map(vbp => vbp.balance))
  const allBalances = await Promise.all([ fullBalances, vaultBalances ])

  const validBalances = []

  for(let i = 0; i < allBalances[ 0 ].length; i++) {
    let pairAddr = fullBalPromises[ i ].pairAddr

    if(allBalances[ 0 ][ i ].isZero() && allBalances[ 1 ][ i ].isZero()) {
      continue
    } else {
      validBalances.push({ tokenA: fullBalPromises[ i ].tokenA, tokenB: fullBalPromises[ i ].tokenB, pairAddr, fullBal: allBalances[ 0 ][ i ], vaultBal: allBalances[ 1 ][ i ] })
    }
  }
 
  return validBalances
}

const calculateRemovableLiquidity = async (provider: Web3Provider, chainId: number, tokenAAddr: string, tokenBAddr: string, amount: string, balances?: Balances): Promise<RemovableLiquidity> => {
  const pair = await getPair(provider, chainId, tokenAAddr, tokenBAddr)
  if(pair.address === ZERO_ADDR) return { amountA: ZERO, amountB: ZERO }
  const amountLp = parseUnits(amount, Units.ether)
  const totalSupply = await pair.totalSupply()
  const pairLpBal = await pair.balanceOf(pair.address)
  const { addrA, addrB } = baseToWrapped(chainId, tokenAAddr, tokenBAddr)
  const { balA, balB } = balances ? balances : await getPairBalances(provider, chainId, addrA, addrB)
  const amountA = ((amountLp.add(pairLpBal)).mul(balA)).div(totalSupply)
  const amountB = ((amountLp.add(pairLpBal)).mul(balB)).div(totalSupply)
  return { amountA, amountB }
}

const getAmountOut = async (provider: Web3Provider, chainId: number, amountIn: BigNumber, tokenAAddr: string, tokenBAddr: string, reserves?: Reserves): Promise<BigNumber> => {
  const router = getRouter(provider, chainId)
  const { reserveA, reserveB } = reserves ? reserves : await getReserves(provider, chainId, tokenAAddr, tokenBAddr)
  return await router.getAmountOut(amountIn, reserveA, reserveB)
}

const getAmountIn = async (provider: Web3Provider, chainId: number, amountOut: BigNumber, tokenAAddr: string, tokenBAddr: string, reserves?: Reserves): Promise<BigNumber> => {
  const router = getRouter(provider, chainId)
  const { reserveA, reserveB } = reserves ? reserves : await getReserves(provider, chainId, tokenAAddr, tokenBAddr)
  if(amountOut.gte(reserveB)) return ZERO
  return await router.getAmountIn(amountOut, reserveA, reserveB)
}

const calculateProviderFee = (amountIn: BigNumber, field: ExactField): BigNumber => {
  if(field === ExactField.From) {
    const feeNumerator = BigNumber.from("2")
    const feeDenominator = BigNumber.from("1000")
    return amountIn.mul(feeNumerator).div(feeDenominator)
  } else if(field === ExactField.To) {
    const feeNumerator = BigNumber.from("1002")
    const feeDenominator = BigNumber.from("1000")
    return amountIn.mul(feeNumerator).div(feeDenominator).sub(amountIn)
  } else return ZERO
}

const amountToWeiDecimals = (amount: BigNumber, decimals: number): BigNumber => {
  const difference = 10 ** (18 - decimals)
  return amount.mul(difference)
}

const getCurrentPrice = (reserves: Reserves, decimalsA: number, decimalsB: number): BigNumber => {
  const reserveAWeiDecimals = amountToWeiDecimals(reserves.reserveA, decimalsA)
  const reserveBWeiDecimals = amountToWeiDecimals(reserves.reserveB, decimalsB)
  const priceWei = reserveAWeiDecimals.mul(ONE_ETHER).div(reserveBWeiDecimals)
  return priceWei
}

const calculatePriceImpact = (quotePrice: BigNumber, amountIn: BigNumber, amountOut: BigNumber, decimalsIn: number, decimalsOut: number): PriceImpact => {
  const amountInWeiDecimals = amountToWeiDecimals(amountIn, decimalsIn)
  const amountOutWeiDecimals = amountToWeiDecimals(amountOut, decimalsOut)
  const actualPrice = amountInWeiDecimals.mul(ONE_ETHER).div(amountOutWeiDecimals)
  const priceDiff = actualPrice.sub(quotePrice)
  const percentage = formatUnits(priceDiff.mul(ONE_ETHER).mul("100").div(quotePrice), Units.ether)
  return { actualPrice, percentage }
}

const getTermDate = async (provider: Web3Provider, chainId: number, term: Term): Promise<string> => {
  if(term === Term.Short) return "0987654321"
  else if(term === Term.Medium) return "1234567890"
  else if(term === Term.Long) return "1672531199"
  else return "0"
}

const getLiquiditySliceAmounts = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string, termEnd: string): Promise<TotalBurned> => {
  const vault = getVault(provider, chainId)
  let totalPromise, individualPromise
  if(tokenAAddr === ZERO_ADDR) {
    totalPromise = vault.liquiditySliceAmountBurnedETH(tokenBAddr, termEnd)
    individualPromise = vault.burnedByETH(tokenBAddr, termEnd, accounts[ 0 ])
  } else if(tokenBAddr === ZERO_ADDR) {
    totalPromise = vault.liquiditySliceAmountBurnedETH(tokenAAddr, termEnd)
    individualPromise = vault.burnedByETH(tokenAAddr, termEnd, accounts[ 0 ])
  } else {
    totalPromise = vault.liquiditySliceAmountBurned(tokenAAddr, tokenBAddr, termEnd)
    individualPromise = vault.burnedBy(tokenAAddr, tokenBAddr, termEnd, accounts[ 0 ])
  }

  const results = await Promise.all([ totalPromise, individualPromise ])
  const [ liquiditySliceAmountBurned, burnedBy ] = results

  return { liquiditySliceAmountBurned, burnedBy }
}

const addFmn = async (connector: Connector, provider: Web3Provider, chainId: number): Promise<void> => {
  if(connector !instanceof MetaMask) return
  if(!provider.provider.request) return
  const tokensOnNetwork = await getTokensOnNetwork(LiquidityType.Spot)
  try {
    await provider.provider.request({
      method: "wallet_watchAsset",
      params: {
        // @ts-ignore
        type: "ERC20",
        options: {
          address: tokensOnNetwork[ 2 ].address,
          symbol: tokensOnNetwork[ 2 ].symbol,
          decimals: 18,
          image: "https://api.freemoon.exchange/logos/FMN.png"
        }
      },
    })
  } catch(err: any) {
    const contractError = getError(provider, chainId, err, { symbolA: "", symbolB: "" })
    if(contractError instanceof Error) throw contractError
    else console.log(contractError)
  }
}

const addFree = async (connector: Connector, provider: Web3Provider, chainId: number): Promise<void> => {
  if(connector !instanceof MetaMask) return
  if(!provider.provider.request) return
  const tokensOnNetwork = await getTokensOnNetwork(LiquidityType.Spot)
  try {
    await provider.provider.request({
      method: "wallet_watchAsset",
      params: {
        // @ts-ignore
        type: "ERC20",
        options: {
          address: tokensOnNetwork[ 3 ].address,
          symbol: tokensOnNetwork[ 3 ].symbol,
          decimals: 18,
          image: "https://api.freemoon.exchange/logos/FREE.png"
        }
      },
    })
  } catch(err: any) {
    const contractError = getError(provider, chainId, err, { symbolA: "", symbolB: "" })
    if(contractError instanceof Error) throw contractError
    else console.log(contractError)
  }
}


export {
  nullConnection,
  nullNetwork,
  ZERO,
  ExactField,
  Config,
  Net,
  StatusCode,
  Term,
  LiquidityType,
  WalletListOptions,
  getBaseBal,
  getNetwork,
  getTokens,
  loadLogos,
  getGasFees,
  hideIdenticalTokens,
  deductGasFee,
  getTokensOnNetwork,
  getContractsOnNetwork,
  getUnavailablePairsForToken,
  getSymbols,
  getToken20,
  getToken759,
  getFactory,
  getRouter,
  getPair,
  getVault,
  getTokenBal,
  getPairBalances,
  getRouterAllowance,
  checkIfNeedsApproval,
  getBurnAllowance,
  checkIfBurnNeedsApproval,
  checkIfPairNeedsApproval,
  checkIfPairExists,
  getReserves,
  quote,
  calculateNetSlippage,
  getLPBalance,
  loadLPBalances,
  calculateRemovableLiquidity,
  getAmountOut,
  getAmountIn,
  calculateProviderFee,
  calculatePriceImpact,
  getCurrentPrice,
  getTermDate,
  getLiquiditySliceAmounts,
  addFree,
  addFmn
}

export type { Connection, TxConfig, GasFees, NetAmounts, Status, LPBalance, RemovableLiquidity, Symbols, Reserves, Balances, PriceImpact, TotalBurned }
