
import React, { useState, useEffect, useCallback, Dispatch, SetStateAction } from "react"
import { BigNumber } from "ethers"
import { useWeb3React } from "@web3-react/core"

import { Units, parseUnits, formatUnits, significantDigits, ZERO, tsToTime } from "../utils/web2Utils"
import { GasFees, TxConfig, Net, NetAmounts, Status, Balances, RemovableLiquidity, getLPBalance, checkIfPairNeedsApproval, getGasFees, checkIfPairExists, calculateRemovableLiquidity, calculateNetSlippage, StatusCode, getPairBalances, hideIdenticalTokens } from "../utils/web3Utils"

import { approveRouterAllLP, removeLiquidity } from "../utils/interactions"
import { Pair, TokenOnNetwork, defaultToken, LP_DECIMALS } from "../utils/tokens"

import Picker from "../components/Picker"
import TxConfigure from "../components/TxConfigure"
import StatusMessage from "../components/StatusMessage"

import { Theme } from "../theme"
import { DropDownIcon, SettingsIcon, Title, MainRow, InputToken, InputAmount, InputAmountPlaceholder, BalanceRow, ApproveButton, TokenBalance, RowSpacer, SettingsButton, RemoveButton, InfoDisplay, InfoDisplayRow, InfoDisplayKey, InfoDisplayData, Icon, ApprovalLoader, ConfirmLoader, InfoDisplayContainer } from "../component-styles"

interface Props {
  tokens: Pair | null,
  setTokens: Dispatch<SetStateAction<Pair | null>>
}


const RemoveLiquidity: React.FC<Props> = ({ tokens, setTokens }) => {
  const [ tokenA, setTokenA ] = useState<TokenOnNetwork>(defaultToken)
  const [ tokenB, setTokenB ] = useState<TokenOnNetwork>(defaultToken)
 
  const [ amount, setAmount ] = useState<string | null>(null)
  const [ bal, setBal ] = useState<BigNumber | null>(null)

  const [ pairBals, setPairBals ] = useState<Balances>({ balA: ZERO, balB: ZERO })

  const [ removableLiquidity, setRemovableLiquidity ] = useState<RemovableLiquidity | null>(null)

  const [ gasPriceOptions, setGasPriceOptions ] = useState<GasFees>({ low: ZERO, medium: ZERO, high: ZERO })
  const [ txConfig, setTxConfig ] = useState<TxConfig>({ slippage: "0", gasPrice: "0", deadline: "0" })

  const [ pairExists, setPairExists ] = useState<boolean>(false)

  const [ netSlippage, setNetSlippage ] = useState<NetAmounts>({ tokenA: ZERO, tokenB: ZERO })

  const [ status, setStatus ] = useState<Status | null>(null)

  const [ approvalLoading, setApprovalLoading ] = useState<boolean>(false)
  const [ confirmLoading, setConfirmLoading ] = useState<boolean>((false))

  const [ displayAPicker, setDisplayAPicker ] = useState<boolean>(false)
  const [ displayBPicker, setDisplayBPicker ] = useState<boolean>(false)
  const [ displayApproval, setDisplayApproval ] = useState<boolean>(false)
  const [ displayTxConfig, setDisplayTxConfig ] = useState<boolean>(false)
  const [ displayConfirm, setDisplayConfirm ] = useState<boolean>(false)



  const { provider, chainId, accounts } = useWeb3React()



  const getGasOptions = useCallback(async () => {
    if(!provider) return
    const { low, medium, high } = await getGasFees(provider)
    setGasPriceOptions({ low, medium, high })
    setTxConfig({ slippage: "1", gasPrice: formatUnits(medium, Units.gwei), deadline: "5" })
  }, [ provider ])

  const refreshPairBalances = useCallback(async () => {
    if(!provider || !chainId) return
    if(tokenA.address && tokenB.address) {
      console.log(`Refreshing Remove Liquidity Pair Balances. ${ tsToTime() }`)
      const currentBals = await getPairBalances(provider, chainId, tokenA.address, tokenB.address)
      setPairBals(currentBals)
    }
  }, [ provider, chainId, tokenA, tokenB ])

  const refreshBalance = useCallback(async () => {
    if(!provider || !chainId || !accounts) return
    setAmount(null)
    setBal(null)
    if(tokenA.address && tokenB.address) {
      const lpBal = await getLPBalance(provider, chainId, accounts, tokenA.address, tokenB.address)
      setBal(lpBal)
    }
  }, [ provider, chainId, accounts, tokenA, tokenB ])

  const checkAllowance = useCallback(async () => {
    if(!provider || !chainId || !accounts) return
    if(tokenA.address && tokenB.address) {
      const needsApprovalA = await checkIfPairNeedsApproval(provider, chainId, accounts, tokenA.address, tokenB.address)
      setDisplayApproval(needsApprovalA)
    }
  }, [ provider, chainId, accounts, tokenA, tokenB ])

  const checkPair = useCallback(async () => {
    if(!provider || !chainId) return
    setPairExists(false)
    if(tokenA.address && tokenB.address) {
      const pairDoesExist = await checkIfPairExists(provider, chainId, tokenA.address, tokenB.address)
      setPairExists(pairDoesExist)
    }
  }, [ provider, chainId, tokenA, tokenB ])

  const checkRemovableLiquidity = useCallback(async () => {
    if(!provider || !chainId) return
    setRemovableLiquidity({ amountA: ZERO, amountB: ZERO })
    if(tokenA.address && tokenB.address && amount && pairExists && (Number(amount) !== 0) && !pairBals.balA.isZero() && !pairBals.balB.isZero()) {
      const liquidityRemovable = await calculateRemovableLiquidity(provider, chainId, tokenA.address, tokenB.address, amount, pairBals)
      setRemovableLiquidity(liquidityRemovable)
    }
  }, [ provider, chainId, tokenA, tokenB, amount, pairExists, pairBals ])

  const updateNetSlippage = useCallback(() => {
    if(displayAPicker || displayBPicker) return
    if(tokenA.address && tokenB.address && bal && !bal.isZero() && amount && pairExists && removableLiquidity) {
      const minTokenAReceived = calculateNetSlippage(removableLiquidity.amountA, txConfig.slippage, Net.Min)
      const minTokenBReceived = calculateNetSlippage(removableLiquidity.amountB, txConfig.slippage, Net.Min)
      setNetSlippage({ tokenA: minTokenAReceived, tokenB: minTokenBReceived })
    } else {
      setNetSlippage({ tokenA: ZERO, tokenB: ZERO })
    }
  }, [ tokenA, tokenB, bal, amount, pairExists, removableLiquidity, txConfig.slippage, displayAPicker, displayBPicker ])

  const checkConfirmActive = useCallback(() => {
    setDisplayConfirm(Boolean(tokenA.address && tokenB.address && amount && removableLiquidity && pairExists && !netSlippage.tokenA.isZero() && !netSlippage.tokenB.isZero() && !displayApproval))
  }, [ tokenA, tokenB, amount, removableLiquidity, pairExists, netSlippage, displayApproval ])



  const handlePickerAClick = () => {
    if(!displayBPicker) setDisplayAPicker(true)
  }

  const handlePickerBClick = () => {
    if(!displayAPicker) setDisplayBPicker(true)
  }

  const handleApproveClick = async () => {
    if(!provider || !chainId) return
    if(!tokenA.address || !tokenB.address) return
    try {
      setApprovalLoading(true)
      await approveRouterAllLP(provider, chainId, tokenA.address, tokenB.address)
      setApprovalLoading(false)
      await checkAllowance()
    } catch(err: any) {
      setStatus({ msg: err.message, code: StatusCode.Fail })
      setApprovalLoading(false)
      setDisplayApproval(true)
    }
  }

  const handleAmountChange = async (amount: string) => {
    if(!amount) {
      setAmount(null)
      return
    }
    setAmount(amount)
  }

  const handleMaxClick = () => {
    if(!bal) {
      setAmount(null)
      return
    }
    const balString = formatUnits(bal, Units.ether)
    handleAmountChange(balString)
  }

  const handleSettingsClick = () => {
    setDisplayTxConfig(true)
  }

  const handleConfirmClick = async () => {
    if(!provider || !chainId || !accounts) return
    if(displayConfirm && amount && !displayApproval) {
      const amountWei = parseUnits(amount, Units.ether)

      if(bal && amountWei.gt(bal)) {
        setStatus({ msg: `Amount ${ tokenA.symbol }/${ tokenB.symbol } exceeds balance.`, code: StatusCode.Warn })
        setDisplayConfirm(true)
      } else if(netSlippage.tokenA.gt(pairBals.balA)) {
        setStatus({ msg: `Insufficient reserves for ${ tokenA.symbol }.`, code: StatusCode.Warn })
        setDisplayConfirm(true)
      } else if(netSlippage.tokenB.gt(pairBals.balB)) {
        setStatus({ msg: `Insufficient reserves for ${ tokenB.symbol }.`, code: StatusCode.Warn })
      } else {
        setConfirmLoading(true)
        setDisplayConfirm(false)
        try {
          const tx = await removeLiquidity(provider, chainId, accounts, tokenA.address, tokenB.address, amountWei, txConfig, netSlippage)
          const { returnValues, receipt } = tx ? tx : { returnValues: "", receipt:  "" }
          const [ amountARemoved, amountBRemoved ] = returnValues && returnValues.length ? returnValues : [ ZERO, ZERO ]
          const txResult = (!amountARemoved.isZero() && !amountBRemoved.isZero()
            ? { msg: `Removed ${ significantDigits(formatUnits(amountARemoved, tokenA.decimals), 8) } ${ tokenA.symbol }, ${ significantDigits(formatUnits(amountBRemoved, tokenB.decimals), 8) } ${ tokenB.symbol }.`, code: StatusCode.Success, tx: receipt.transactionHash }
            : null
          )

          setConfirmLoading(false)
          setAmount(null)
          setStatus(txResult)
          refreshBalance()
        } catch(err: any) {
          setStatus({ msg: err.message, code: StatusCode.Fail })
          setConfirmLoading(false)
          setDisplayConfirm(true)
        }
      }
    }
  }



  useEffect(() => {
    if(tokens) {
      setTokenA(tokens.tokenA)
      setTokenB(tokens.tokenB)
    }

    return () => setTokens(null)
  }, [ tokens, setTokens ])

  useEffect(() => {
    getGasOptions()
  }, [ getGasOptions ])

  useEffect(() => {
    refreshPairBalances()
    const refreshInterval = setInterval(() => refreshPairBalances(), 15000)
    return () => clearInterval(refreshInterval)
  }, [ refreshPairBalances ])

  useEffect(() => {
    refreshBalance()
    checkAllowance()
  }, [ refreshBalance, checkAllowance ])

  useEffect(() => {
    checkPair()
  }, [ checkPair ])

  useEffect(() => {
    checkRemovableLiquidity()
  }, [ checkRemovableLiquidity ])

  useEffect(() => {
    const updateTimeout = setTimeout(() => updateNetSlippage(), 1000)
    return () => clearTimeout(updateTimeout)
  }, [ updateNetSlippage ])

  useEffect(() => {
    checkConfirmActive()
  }, [ checkConfirmActive ])



  return (
    <div>
      <Title theme={ Theme }>REMOVE</Title>

      <BalanceRow>
        <ApproveButton onClick={ () => !approvalLoading && !confirmLoading ? handleApproveClick() : null } theme={ Theme } isActive={ displayApproval }>{ !approvalLoading ? "APPROVE PAIR" : <><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/></> }</ApproveButton>
        <TokenBalance onClick={ handleMaxClick } theme={ Theme } isActive={ Boolean(bal) }>{ bal && bal.isZero() ? "0.0" : significantDigits(formatUnits(bal, LP_DECIMALS), 8) }</TokenBalance>
      </BalanceRow>

      <MainRow isBottom={ false }>
        <InputToken onClick={ handlePickerAClick } theme={ Theme } isSelected={ Boolean(tokenA.address) }><Icon src={ tokenA.logo } size={ "30" }/>{ tokenA.symbol }<DropDownIcon/></InputToken>
        <InputAmount type="number" step="any"  min="0" placeholder={ "0.0" } value={ amount ? amount : "" } onChange={ e => handleAmountChange(e.target.value) } theme={ Theme }/>
      </MainRow>

      <RowSpacer size={ "5" }/>

      <MainRow isBottom={ true }>
        <InputToken onClick={ handlePickerBClick } theme={ Theme } isSelected={ Boolean(tokenB.address) }><Icon src={ tokenB.logo } size={ "30" }/>{ tokenB.symbol }<DropDownIcon/></InputToken>
        <InputAmountPlaceholder><SettingsButton onClick={ handleSettingsClick } theme={ Theme }><SettingsIcon/></SettingsButton><RemoveButton onClick={ handleConfirmClick } theme={ Theme } isActive={ Boolean(displayConfirm && !confirmLoading) }>{ !confirmLoading ? "Remove" : <><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/></> }</RemoveButton></InputAmountPlaceholder>
      </MainRow>

      <InfoDisplay theme={ Theme } isActive={ Boolean(!netSlippage.tokenA.isZero() && !netSlippage.tokenB.isZero()) }>
        <InfoDisplayContainer theme= { Theme }>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Minimum { tokenA.symbol } to be removed</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(netSlippage.tokenA, tokenA.decimals), 8) } { tokenA.symbol }</InfoDisplayData></InfoDisplayRow>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Minimum { tokenB.symbol } to be removed</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(netSlippage.tokenB, tokenB.decimals), 8) } { tokenB.symbol }</InfoDisplayData></InfoDisplayRow>
        </InfoDisplayContainer>
      </InfoDisplay>

      <InfoDisplay theme={ Theme } isActive={ Boolean(!netSlippage.tokenA.isZero() && !netSlippage.tokenB.isZero()) }>
        <InfoDisplayContainer theme={ Theme }>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Protocol Fee</InfoDisplayKey><InfoDisplayData>0.2%</InfoDisplayData></InfoDisplayRow>
        </InfoDisplayContainer>
      </InfoDisplay>

      {
        displayAPicker && chainId
          ? <Picker onClose={ () => setDisplayAPicker(false) } unavailable={ hideIdenticalTokens(chainId, tokenB.address) } setPick={ setTokenA } title="Select Token A"/>
          : ""
      }

      {
        displayBPicker && chainId
          ? <Picker onClose={ () => setDisplayBPicker(false) } unavailable={ hideIdenticalTokens(chainId, tokenA.address) } setPick={ setTokenB } title="Select Token B"/>
          : ""
      }

      {
        displayTxConfig 
        ? <TxConfigure onClose={ () => setDisplayTxConfig(false) } txConfig={ txConfig } setTxConfig={ setTxConfig } gasPriceOptions={ gasPriceOptions }/>
        : ""
      }

      {
        status && status.msg
          ? <StatusMessage onClose={ () => setStatus(null) } status={ status }/>
          : ""
      }
    </div>
  )
}

export default RemoveLiquidity
