
import React, { useState, useEffect, useRef, useCallback } from "react"
import ReactDOM from "react-dom"

import { Units, formatUnits } from "../utils/web2Utils"
import { TxConfig, GasFees, Config } from "../utils/web3Utils"

import { Theme } from "../theme"
import { CloseIcon, ModalOverlay, ModalTitle, ModalClose, TxConfigPopUp, TxConfigRow, TxConfigKey, TxConfigOptionTray, TxConfigOptionPreset, TxConfigOptionCustom, TxConfigConfirmSpacer, TxConfigConfirmButton } from "../component-styles"


interface Props {
  onClose: () => void,
  txConfig: TxConfig,
  setTxConfig: React.Dispatch<React.SetStateAction<TxConfig>>,
  gasPriceOptions: GasFees
}



const TxConfigure: React.FC<Props> = ({ onClose, txConfig, setTxConfig, gasPriceOptions }) => {
  const [ slippage, setSlippage ] = useState<string | null>(txConfig.slippage)
  const [ gasPrice, setGasPrice ] = useState<string | null>(txConfig.gasPrice)
  const [ deadline, setDeadline ]  = useState<string | null>(txConfig.deadline)



  const overlay = useRef<HTMLDivElement>(null)
  const modal = useRef<HTMLDivElement>(null)



  const exitModal = useCallback((e: MouseEvent) => {
    if(overlay?.current?.contains(e.target as Node) && !modal?.current?.contains(e.target as Node)) {
      onClose()
    }
  }, [ onClose ])

  const handleValueChange = (value: string, config: Config) => {
    const valueOrNull = value ? value : null
    switch(config) {
      case Config.Slippage:
        setSlippage(valueOrNull)
        break
      case Config.GasPrice:
        setGasPrice(valueOrNull)
        break
      case Config.Deadline:
        setDeadline(valueOrNull)
        break
    }
  }

  const handleConfirm = () => { 
    let slippageOut = txConfig.slippage
    let gasPriceOut = txConfig.gasPrice
    let deadlineOut = txConfig.deadline

    if(slippage) slippageOut = slippage
    if(gasPrice && (Number(gasPrice) !== 0)) gasPriceOut = gasPrice
    if(deadline && (Number(deadline) !== 0)) deadlineOut = deadline

    setTxConfig({ slippage: slippageOut, gasPrice: gasPriceOut, deadline: deadlineOut })
    onClose()
  }



  useEffect(() => {
    document.addEventListener("mousedown", exitModal)

    return () => {
      document.removeEventListener("mousedown", exitModal)
    }
  }, [ exitModal ])



  return ReactDOM.createPortal(
    <ModalOverlay ref={ overlay }>
      <TxConfigPopUp ref={ modal } theme={ Theme }>
        <TxConfigRow>
          <ModalTitle theme={ Theme }>
            Settings
          </ModalTitle>
          <ModalClose onClick={ () => onClose() } theme={ Theme }><CloseIcon/></ModalClose>
        </TxConfigRow>
        <TxConfigRow>
          <TxConfigKey>
            Slippage (%)
          </TxConfigKey>
          <TxConfigOptionTray>
            <TxConfigOptionPreset onClick={ () => handleValueChange("0.5", Config.Slippage) } theme={ Theme }>0.5</TxConfigOptionPreset>
            <TxConfigOptionPreset onClick={ () => handleValueChange("1", Config.Slippage) } theme={ Theme }>1</TxConfigOptionPreset>
            <TxConfigOptionPreset onClick={ () => handleValueChange("5", Config.Slippage) } theme={ Theme }>5</TxConfigOptionPreset>
            <TxConfigOptionCustom type="number" min="0" value={ slippage ? slippage : "" } onChange={ (e) => handleValueChange(e.target.value, Config.Slippage) } theme={ Theme }/>
          </TxConfigOptionTray>
        </TxConfigRow>
        <TxConfigRow>
          <TxConfigKey>
            Gas Price (gwei)
          </TxConfigKey>
          <TxConfigOptionTray>
            <TxConfigOptionPreset onClick={ () => handleValueChange(formatUnits(gasPriceOptions.low, Units.gwei), Config.GasPrice) } theme={ Theme }>{ formatUnits(gasPriceOptions.low, Units.gwei) }</TxConfigOptionPreset>
            <TxConfigOptionPreset onClick={ () => handleValueChange(formatUnits(gasPriceOptions.medium, Units.gwei), Config.GasPrice) } theme={ Theme }>{ formatUnits(gasPriceOptions.medium, Units.gwei) }</TxConfigOptionPreset>
            <TxConfigOptionPreset onClick={ () => handleValueChange(formatUnits(gasPriceOptions.high, Units.gwei), Config.GasPrice) } theme={ Theme }>{ formatUnits(gasPriceOptions.high, Units.gwei) }</TxConfigOptionPreset>
            <TxConfigOptionCustom type="number" min="0" value={ gasPrice ? gasPrice : "" } onChange={ (e) => handleValueChange(e.target.value, Config.GasPrice) } theme={ Theme }/>
          </TxConfigOptionTray>
        </TxConfigRow>
        <TxConfigRow>
          <TxConfigKey>
            Deadline (minutes)
          </TxConfigKey>
          <TxConfigOptionTray>
            <TxConfigOptionPreset onClick={ () => handleValueChange("1", Config.Deadline) } theme={ Theme }>1</TxConfigOptionPreset>
            <TxConfigOptionPreset onClick={ () => handleValueChange("5", Config.Deadline) } theme={ Theme }>5</TxConfigOptionPreset>
            <TxConfigOptionPreset onClick={ () => handleValueChange("10", Config.Deadline) } theme={ Theme }>10</TxConfigOptionPreset>
            <TxConfigOptionCustom type="number" min="0" value={ deadline ? deadline : "" } onChange={ (e) => handleValueChange(e.target.value, Config.Deadline) } theme={ Theme }/> 
          </TxConfigOptionTray>
        </TxConfigRow>
        <TxConfigRow>
          <TxConfigConfirmSpacer>
            <TxConfigConfirmButton onClick={ handleConfirm } theme={ Theme }>
              Confirm
            </TxConfigConfirmButton>
          </TxConfigConfirmSpacer>
        </TxConfigRow>
      </TxConfigPopUp>
    </ModalOverlay>,
    document.getElementById("config")!
  )
}

export default TxConfigure