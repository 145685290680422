
import { DefaultTheme } from "styled-components"

// const Theme: DefaultTheme = {
//   colors: {
//     main: "#bbd5f1",
//     secondary: "#edfbff",
//     tertiary: "#e6f4f1",
//     highlight: "#009bf9",
//     text: "#222"
//   }
// }

const Theme: DefaultTheme = {
  colors: {
    // main: "#bbd5f1",
    // main: "#c5e1ff",
    main: "#cdebff",
    secondary: "#e8f8ff",
    // tertiary: "#859eb9",
    tertiary: "#96b3c6",
    highlight: "#5daceb",
    text: "#19354b",
    highlightFaint: "rgba(93, 172, 235, 0.5)"
  }
}

// const Theme: DefaultTheme = {
//   colors: {
//     main: "#859eb9",
//     secondary: "#19354b",
//     tertiary: "#bbd5f1",
//     highlight: "",
//     text: "#e8f8ff"
//   }
// }

// const PALETTE: string[] = [
//   "#bbd5f1",
//   "#009bf9",
//   "#edfbff",
//   "#e6f4f1"
// ]

export { Theme }
