
import { useState, useEffect, useRef, useCallback } from "react"
import ReactDOM from "react-dom"

import { getTokensOnNetwork, LiquidityType } from "../utils/web3Utils"

import { TokenOnNetwork, defaultToken } from "../utils/tokens"

import { Theme } from "../theme"
import { Icon, CloseIcon, ModalOverlay, ModalTitle, ModalClose, PickerPopUp, PickerRow, PickerSearch, PickerOptionsList, PickerOption, PickerText, PickerTokenTypeRow, PickerTokenTypeTab, PickerTokenListLoading, ConfirmLoader } from "../component-styles"


interface Props {
  onClose: () => void,
  unavailable: string[],
  setPick: (selectedToken: TokenOnNetwork) => void,
  title: string
}

const Picker: React.FC<Props> = ({ onClose, unavailable, setPick, title }) => {
  const [ liquidityType, setLiquidityType ] = useState<LiquidityType>(LiquidityType.Spot)

  const [ searchValue, setSearchValue ] = useState<string>("")
  const [ list, setList ] = useState<TokenOnNetwork[]>([])
  const [ trimmedList, setTrimmedList ] = useState<TokenOnNetwork[]>([])

  const [ listLoading, setListLoading ] = useState<boolean>(false)



  const overlay = useRef<HTMLDivElement>(null)
  const modal = useRef<HTMLDivElement>(null)



  const exitModal = useCallback((e: MouseEvent) => {
    if(overlay?.current?.contains(e.target as Node) && !modal?.current?.contains(e.target as Node)) {
      onClose()
    }
  }, [ onClose ])



  useEffect(() => {
    document.addEventListener("mousedown", exitModal)

    return () => {
      document.removeEventListener("mousedown", exitModal)
    }
  }, [ exitModal ])

  useEffect(() => { 
    const loadTokenList = async () => {
      setListLoading(true)
      const defaultArr = [ defaultToken ]
      const tokens = await getTokensOnNetwork(liquidityType, unavailable)
      setList(defaultArr.concat(tokens))
      setListLoading(false)
    }

    loadTokenList()
  }, [ liquidityType, unavailable ])

  useEffect(() => { 
    if(!searchValue) {
      setTrimmedList([])
      return
    }
    let currentOptions = list.filter((tkn: TokenOnNetwork) => {
      const optionNameLower = tkn.name.toLowerCase()
      const optionSymbolLower = tkn.symbol.toLowerCase()
      const searchValueLower = searchValue.toLowerCase()
      return optionNameLower.includes(searchValueLower) || optionSymbolLower.includes(searchValueLower) || tkn.symbol === defaultToken.symbol
    })

    setTrimmedList(currentOptions)
  }, [ list, searchValue, onClose, setPick ])



  const displayList = () => {
    let listToDisplay = list
    if(trimmedList.length > 0) listToDisplay = trimmedList

    return listToDisplay.map((tkn: TokenOnNetwork) => {
      return (
        <PickerOption
          theme={ Theme }
          key={ tkn.name }
          onClick={() => {
            setPick(tkn)
            setSearchValue("")
            onClose()
          }}>
          <Icon src={ tkn.logo || require("../images/placeholder.png") } size={ "50" }/>
          <PickerText>{ tkn.symbol }</PickerText>
        </PickerOption>
      )
    })
  }

  const handleTypeChange = (lType: LiquidityType) => {
    setSearchValue("")
    setTrimmedList([])
    setLiquidityType(lType)
  }



  return ReactDOM.createPortal(
    <ModalOverlay ref={ overlay } theme={ Theme }>
      <PickerPopUp ref={ modal } theme={ Theme }>
        <PickerRow theme={ Theme }>
          <ModalTitle theme={ Theme }>
            { title }
          </ModalTitle>
          <ModalClose onClick={ () => onClose() } theme={ Theme }><CloseIcon/></ModalClose>
        </PickerRow>
        <PickerRow theme={ Theme }>
          <PickerSearch type="text" spellCheck="false" placeholder="Search" onChange={ e => setSearchValue(e.target.value.toLowerCase()) } theme={ Theme }/>
        </PickerRow>
        <PickerTokenTypeRow>
          <PickerTokenTypeTab onClick={ () => !listLoading ? handleTypeChange(LiquidityType.Spot) : null } theme={ Theme } isActive={ liquidityType === LiquidityType.Spot }>SPOT</PickerTokenTypeTab>
          <PickerTokenTypeTab onClick={ () => !listLoading ? handleTypeChange(LiquidityType.Futures) : null  } theme={ Theme } isActive={ liquidityType === LiquidityType.Futures }>FUTURES</PickerTokenTypeTab>
          <PickerTokenTypeTab onClick={ () => !listLoading ? handleTypeChange(LiquidityType.Options) : null  } theme={ Theme } isActive={ liquidityType === LiquidityType.Options }>OPTIONS</PickerTokenTypeTab>
        </PickerTokenTypeRow>
        <PickerOptionsList isActive={ !listLoading } theme={ Theme }>
          { !listLoading ? displayList() : <PickerTokenListLoading theme={ Theme }><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/></PickerTokenListLoading> }
        </PickerOptionsList>
      </PickerPopUp>
    </ModalOverlay>,
    document.getElementById("picker")!
  )
}

export default Picker

