
import { BigNumber } from "ethers"
import { Web3Provider } from "@ethersproject/providers"

import { TxConfig, NetAmounts, getContractsOnNetwork, getToken20, getPair, getRouter, getVault, ExactField, getSymbols } from "./web3Utils"
import { Units, parseUnits, formatUnits, MAX_UINT, nowPlusTime } from "./web2Utils"

import { NON_EXISTENT_PAIR, getError } from "./errors"
import { ZERO_ADDR } from "./tokens"


const approveRouterAll = async (provider: Web3Provider, chainId: number, tokenAddr: string): Promise<void> => {
  const { routerAddr } = getContractsOnNetwork(chainId)
  const token = getToken20(provider, tokenAddr)
  try {
    const tx = await token.approve(routerAddr, MAX_UINT)
    await tx.wait()
  } catch(err: any) {
    const contractError = getError(provider, chainId, err, { symbolA: tokenAddr, symbolB: tokenAddr })
    if(contractError instanceof Error) throw contractError
    else console.log(contractError)
  }
}

const approveRouterAllLP = async (provider: Web3Provider, chainId: number, tokenAAddr: string, tokenBAddr: string): Promise<void> => {
  const { routerAddr } = getContractsOnNetwork(chainId)
  const pair = await getPair(provider, chainId, tokenAAddr, tokenBAddr)
  if(pair.address === ZERO_ADDR) throw NON_EXISTENT_PAIR()
  try {
    const tx = await pair.approve(routerAddr, MAX_UINT)
    await tx.wait()
  } catch(err: any) {
    const symbols = await getSymbols(tokenAAddr, tokenBAddr)
    const contractError = getError(provider, chainId, err, symbols)
    if(contractError instanceof Error) throw contractError
    else console.log(contractError)
  }
}

const approveVaultAllLP = async (provider: Web3Provider, chainId: number, tokenAAddr: string, tokenBAddr: string): Promise<void> => {
  const { vaultAddr } = getContractsOnNetwork(chainId)
  const pair = await getPair(provider, chainId, tokenAAddr, tokenBAddr)
  if(pair.address === ZERO_ADDR) throw NON_EXISTENT_PAIR()
  try {
    const tx = await pair.approve(vaultAddr, MAX_UINT)
    await tx.wait()
  } catch(err: any) {
    const symbols = await getSymbols(tokenAAddr, tokenBAddr)
    const contractError = getError(provider, chainId, err, symbols)
    if(contractError instanceof Error) throw contractError
    else console.log(contractError)
  }
}





const addLiquidityTokens = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string, amountA: BigNumber, amountB: BigNumber, minTokenA: BigNumber, minTokenB: BigNumber, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  console.log(`
    <<< ADD LIQUIDITY TOKENS >>>
    Address A: ${ tokenAAddr },
    Address B: ${ tokenBAddr },
    Amount A: ${ formatUnits(amountA, Units.ether) },
    Amount B: ${ formatUnits(amountB, Units.ether) },
    Min A: ${ formatUnits(minTokenA, Units.ether) },
    Min B: ${ formatUnits(minTokenB, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.addLiquidity(tokenAAddr, tokenBAddr, amountA, amountB, minTokenA, minTokenB, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const tx = await router.addLiquidity(tokenAAddr, tokenBAddr, amountA, amountB, minTokenA, minTokenB, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err) {
    throw err
  }
}

const addLiquidityETH = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAddr: string, amountETH: BigNumber, amountToken: BigNumber, minToken: BigNumber, minETH: BigNumber, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  console.log(`
    <<< ADD LIQUIDITY ETH >>>
    Address Token: ${ tokenAddr },
    Amount Token: ${ formatUnits(amountToken, Units.ether) },
    Amount ETH: ${ formatUnits(amountETH, Units.ether) },
    Min Token: ${ formatUnits(minToken, Units.ether) },
    Min ETH: ${ formatUnits(minETH, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.addLiquidityETH(tokenAddr, amountToken, minToken, minETH, accounts[ 0 ], deadline, { value: amountETH, gasPrice, gasLimit })
    const tx = await router.addLiquidityETH(tokenAddr, amountToken, minToken, minETH, accounts[ 0 ], deadline, { value: amountETH, gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err) {
    throw err
  }
}

const addLiquidity = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string, amountA: BigNumber, amountB: BigNumber, txConfig: TxConfig, netSlippage: NetAmounts, pairExists: boolean) => {
  const deadlineSeconds = nowPlusTime(txConfig.deadline)
  const gasPriceWei = parseUnits(txConfig.gasPrice, Units.gwei)
  const gasLimit = pairExists ? BigNumber.from("300000") : BigNumber.from("5500000")
  
  try {
    if(tokenAAddr === ZERO_ADDR) {
      const tx = await addLiquidityETH(provider, chainId, accounts, tokenBAddr, amountA, amountB, netSlippage.tokenB, netSlippage.tokenA, deadlineSeconds, gasPriceWei, gasLimit)
      return { receipt: tx.receipt, returnValues: [ tx.returnValues[ 1 ], tx.returnValues[ 0 ], tx.returnValues[ 2 ] ] }
    } else if(tokenBAddr === ZERO_ADDR) {
      return await addLiquidityETH(provider, chainId, accounts, tokenAAddr, amountB, amountA, netSlippage.tokenA, netSlippage.tokenB, deadlineSeconds, gasPriceWei, gasLimit)
    } else {
      return await addLiquidityTokens(provider, chainId, accounts, tokenAAddr, tokenBAddr, amountA, amountB, netSlippage.tokenA, netSlippage.tokenB, deadlineSeconds, gasPriceWei, gasLimit)
    }
  } catch(err: any) {
    const symbols = await getSymbols(tokenAAddr, tokenBAddr)
    const contractError = getError(provider, chainId, err, symbols)
    if(contractError instanceof Error) throw contractError
    else console.log(contractError)
  }
}





const removeLiquidityTokens = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string, amount: BigNumber, minTokenA: BigNumber, minTokenB: BigNumber, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  console.log(`
    <<< REMOVE LIQUIDITY TOKENS >>>
    Address A: ${ tokenAAddr },
    Address B: ${ tokenBAddr },
    Liquidity: ${ formatUnits(amount, Units.ether) },
    Min A: ${ formatUnits(minTokenA, Units.ether) },
    Min B: ${ formatUnits(minTokenB, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.removeLiquidity(tokenAAddr, tokenBAddr, amount, minTokenA, minTokenB, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const tx = await router.removeLiquidity(tokenAAddr, tokenBAddr, amount, minTokenA, minTokenB, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err: any) {
    throw err
  }
}

const removeLiquidityETH = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAddr: string, amount: BigNumber, minToken: BigNumber, minETH: BigNumber, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  console.log(`
    <<< REMOVE LIQUIDITY ETH >>>
    Address Token: ${ tokenAddr },
    Liquidity: ${ formatUnits(amount, Units.ether) },
    Min Token: ${ formatUnits(minToken, Units.ether) },
    Min ETH: ${ formatUnits(minETH, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.removeLiquidityETH(tokenAddr, amount, minToken, minETH, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const tx = await router.removeLiquidityETH(tokenAddr, amount, minToken, minETH, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err: any) {
    throw err
  }
}

const removeLiquidity = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string, amount: BigNumber, txConfig: TxConfig, netSlippage: NetAmounts) => {
  const deadlineSeconds = nowPlusTime(txConfig.deadline)
  const gasPriceWei = parseUnits(txConfig.gasPrice, Units.gwei)
  const gasLimit = BigNumber.from("500000")

  try {
    if(tokenAAddr === ZERO_ADDR) {
      const tx = await removeLiquidityETH(provider, chainId, accounts, tokenBAddr, amount, netSlippage.tokenB, netSlippage.tokenA, deadlineSeconds, gasPriceWei, gasLimit)
      return { receipt: tx.receipt, returnValues: [ tx.returnValues[ 1 ], tx.returnValues[ 0 ] ] }
    } else if(tokenBAddr === ZERO_ADDR) {
      return await removeLiquidityETH(provider, chainId, accounts, tokenAAddr, amount, netSlippage.tokenA, netSlippage.tokenB, deadlineSeconds, gasPriceWei, gasLimit)
    } else {
      return await removeLiquidityTokens(provider, chainId, accounts, tokenAAddr, tokenBAddr, amount, netSlippage.tokenA, netSlippage.tokenB, deadlineSeconds, gasPriceWei, gasLimit)
    }
  } catch(err: any) {
    const symbols = await getSymbols(tokenAAddr, tokenBAddr)
    const contractError = getError(provider, chainId, err, symbols)
    if(contractError instanceof Error) throw contractError
    else console.log(contractError)
  }
}





const swapExactETHForTokens = async (provider: Web3Provider, chainId: number, accounts: string[], amountIn: BigNumber, minOut: BigNumber, tokenToAddr: string, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  const { wfsnAddr } = getContractsOnNetwork(chainId)
  const path = [ wfsnAddr, tokenToAddr ]
  console.log(`
    <<< SWAP EXACT ETH FOR TOKENS >>>
    Address To: ${ tokenToAddr },
    Amount In: ${ formatUnits(amountIn, Units.ether) },
    Min Out: ${ formatUnits(minOut, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.swapExactETHForTokens(minOut, path, accounts[ 0 ], deadline, { value: amountIn, gasPrice, gasLimit })
    const tx = await router.swapExactETHForTokens(minOut, path, accounts[ 0 ], deadline, { value: amountIn, gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err: any) {
    throw err
  }
}

const swapExactTokensForETH = async (provider: Web3Provider, chainId: number, accounts: string[], amountIn: BigNumber, minOut: BigNumber, tokenFromAddr: string, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  const { wfsnAddr } = getContractsOnNetwork(chainId)
  const path = [ tokenFromAddr, wfsnAddr ]
  console.log(`
    <<< SWAP EXACT TOKENS FOR ETH >>>
    Address From: ${ tokenFromAddr },
    Amount In: ${ formatUnits(amountIn, Units.ether) },
    Min Out: ${ formatUnits(minOut, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.swapExactTokensForETH(amountIn, minOut, path, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const tx = await router.swapExactTokensForETH(amountIn, minOut, path, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err: any) {
    throw err
  }
}

const swapExactTokensForTokens = async (provider: Web3Provider, chainId: number, accounts: string[], amountIn: BigNumber, minOut: BigNumber, tokenFromAddr: string, tokenToAddr: string, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  const path = [ tokenFromAddr, tokenToAddr ]
  console.log(`
    <<< SWAP EXACT TOKENS FOR TOKENS >>>
    Address From: ${ tokenFromAddr },
    Address To: ${ tokenToAddr },
    Amount In: ${ formatUnits(amountIn, Units.ether) },
    Min Out: ${ formatUnits(minOut, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.swapExactTokensForTokens(amountIn, minOut, path, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const tx = await router.swapExactTokensForTokens(amountIn, minOut, path, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err: any) {
    throw err
  }
}

const swapETHForExactTokens = async (provider: Web3Provider, chainId: number, accounts: string[], amountOut: BigNumber, maxIn: BigNumber, tokenToAddr: string, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  const { wfsnAddr } = getContractsOnNetwork(chainId)
  const path = [ wfsnAddr, tokenToAddr ]
  console.log(`
    <<< SWAP ETH FOR EXACT TOKENS >>>
    Address To: ${ tokenToAddr },
    Amount Out: ${ formatUnits(amountOut, Units.ether) },
    Max In: ${ formatUnits(maxIn, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.swapETHForExactTokens(amountOut, path, accounts[ 0 ], deadline, { value: maxIn, gasPrice, gasLimit })
    const tx = await router.swapETHForExactTokens(amountOut, path, accounts[ 0 ], deadline, { value: maxIn, gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err: any) {
    throw err
  }
}

const swapTokensForExactETH = async (provider: Web3Provider, chainId: number, accounts: string[], amountOut: BigNumber, maxIn: BigNumber, tokenFromAddr: string, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  const { wfsnAddr } = getContractsOnNetwork(chainId)
  const path = [ tokenFromAddr, wfsnAddr ]
  console.log(`
    <<< SWAP TOKENS FOR EXACT ETH >>>
    Address From: ${ tokenFromAddr },
    Amount Out: ${ formatUnits(amountOut, Units.ether) },
    Max In: ${ formatUnits(maxIn, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.swapTokensForExactETH(amountOut, maxIn, path, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const tx = await router.swapTokensForExactETH(amountOut, maxIn, path, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err: any) {
    throw err
  }
}

const swapTokensForExactTokens = async (provider: Web3Provider, chainId: number, accounts: string[], amountOut: BigNumber, maxIn: BigNumber, tokenFromAddr: string, tokenToAddr: string, deadline: string, gasPrice: BigNumber, gasLimit: BigNumber) => {
  const router = getRouter(provider, chainId)
  const path = [ tokenFromAddr, tokenToAddr ]
  console.log(`
    <<< SWAP TOKENS FOR EXACT TOKENS >>> 
    Address From: ${ tokenFromAddr },
    Address To: ${ tokenToAddr },
    Amount Out: ${ formatUnits(amountOut, Units.ether) },
    Max In: ${ formatUnits(maxIn, Units.ether) },
    Account: ${ accounts[ 0 ] },
    Deadline: ${ deadline },
    Gas Price: ${ formatUnits(gasPrice, Units.gwei) },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const returnValues = await router.callStatic.swapTokensForExactTokens(amountOut, maxIn, path, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const tx = await router.swapTokensForExactTokens(amountOut, maxIn, path, accounts[ 0 ], deadline, { gasPrice, gasLimit })
    const receipt = await tx.wait()
    return { returnValues, receipt }
  } catch(err: any) {
    throw err
  }
}

const swap = async (provider: Web3Provider, chainId: number, accounts: string[], tokenFromAddr: string, tokenToAddr: string, amountIn: BigNumber, amountOut: BigNumber, txConfig: TxConfig, netSlippage: BigNumber, exactField: ExactField) => {
  const deadlineSeconds = nowPlusTime(txConfig.deadline)
  const gasPriceWei = parseUnits(txConfig.gasPrice, Units.gwei)
  const gasLimit = BigNumber.from("250000")

  try {
    if(exactField === ExactField.From) {
      if(tokenFromAddr === ZERO_ADDR) {
        return await swapExactETHForTokens(provider, chainId, accounts, amountIn, netSlippage, tokenToAddr, deadlineSeconds, gasPriceWei, gasLimit)
      } else if(tokenToAddr === ZERO_ADDR) {
        return await swapExactTokensForETH(provider, chainId, accounts, amountIn, netSlippage, tokenFromAddr, deadlineSeconds, gasPriceWei, gasLimit)
      } else {
        return await swapExactTokensForTokens(provider, chainId, accounts, amountIn, netSlippage, tokenFromAddr, tokenToAddr, deadlineSeconds, gasPriceWei, gasLimit)
      }
    } else if(exactField === ExactField.To) {
      if(tokenFromAddr === ZERO_ADDR) {
        return await swapETHForExactTokens(provider, chainId, accounts, amountOut, netSlippage, tokenToAddr, deadlineSeconds, gasPriceWei, gasLimit)
      } else if(tokenToAddr === ZERO_ADDR) {
        return await swapTokensForExactETH(provider, chainId, accounts, amountOut, netSlippage, tokenFromAddr, deadlineSeconds, gasPriceWei, gasLimit)
      } else {
        return await swapTokensForExactTokens(provider, chainId, accounts, amountOut, netSlippage, tokenFromAddr, tokenToAddr, deadlineSeconds, gasPriceWei, gasLimit)
      }
    }
  } catch(err: any) {
    const symbols = await getSymbols(tokenFromAddr, tokenToAddr)
    const contractError = getError(provider, chainId, err, symbols)
    if(contractError instanceof Error) throw contractError
    else console.log(contractError)
  }
}





const burnLiquiditySliceTokens = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string, amount: BigNumber, termEnd: string, gasLimit: BigNumber) => {
  const vault = getVault(provider, chainId)
  console.log(`
    <<< BURN LIQUIDITY SLICE TOKENS >>> 
    Address A: ${ tokenAAddr },
    Address B: ${ tokenBAddr },
    Amount Burned: ${ formatUnits(amount, Units.ether) },
    Term End: ${ termEnd },
    Account: ${ accounts[ 0 ] },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const tx = await vault.burnLiquiditySlice(tokenAAddr, tokenBAddr, amount, termEnd, { gasLimit })
    const receipt = await tx.wait()
    return receipt
  } catch(err: any) {
    throw err
  }
}

const burnLiquiditySliceETH = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAddr: string, amount: BigNumber, termEnd: string, gasLimit: BigNumber) => {
  const vault = getVault(provider, chainId)
  console.log(`
    <<< BURN LIQUIDITY SLICE ETH >>> 
    Address: ${ tokenAddr },
    Amount Burned: ${ formatUnits(amount, Units.ether) },
    Term End: ${ termEnd },
    Account: ${ accounts[ 0 ] },
    Gas Limit: ${ gasLimit.toString() }
  `)

  try {
    const tx = await vault.burnLiquiditySliceETH(tokenAddr, amount, termEnd, { gasLimit })
    const receipt = await tx.wait()
    return receipt
  } catch(err: any) {
    throw err
  }
}

const burnLiquiditySlice = async (provider: Web3Provider, chainId: number, accounts: string[], tokenAAddr: string, tokenBAddr: string, amount: BigNumber, termEnd: string) => {
  const gasLimit = BigNumber.from("2500000")

  try {
    if(tokenAAddr === ZERO_ADDR) {
      return await burnLiquiditySliceETH(provider, chainId, accounts, tokenBAddr, amount, termEnd, gasLimit)
    } else if(tokenBAddr === ZERO_ADDR) {
      return await burnLiquiditySliceETH(provider, chainId, accounts, tokenAAddr, amount, termEnd, gasLimit)
    } else {
      return await burnLiquiditySliceTokens(provider, chainId, accounts, tokenAAddr, tokenBAddr, amount, termEnd, gasLimit)
    }
  } catch(err: any) {
    const symbols = await getSymbols(tokenAAddr, tokenBAddr)
    const contractError = getError(provider, chainId, err, symbols)
    if(contractError instanceof Error) throw contractError
    else console.log(contractError)
  }
}





export {
  approveRouterAll,
  approveRouterAllLP,
  approveVaultAllLP,
  addLiquidity,
  removeLiquidity,
  swap,
  burnLiquiditySlice
}
