
import { ethers } from "ethers"
import { Web3Provider } from "@ethersproject/providers"
import { serializeError } from "eth-rpc-errors"

import { Symbols } from "./web3Utils"

import { routerAbi, factoryAbi, pairAbi, vaultAbi } from "./abi"


const WEB3_MISSING = (): Error => {
  return new Error(`Web3 Provider Not Detected.`)
}

const UNDEFINED_CHAINID = (): Error => {
  return new Error(`Unknown Chain ID.`)
}

const UNSUPPORTED_CHAIN = (id: number): Error => {
  return new Error(`Unsupported Chain ID: ${ id }`)
}

const FAILED_APPROVAL = (): Error => {
  return new Error(`Approval Transaction Failed.`)
}

const ZERO_BAL = (tokenName: string): Error => {
  return new Error(`Zero Balance of ${ tokenName }.`)
}

const ALLOWANCE_LOW = (tokenName: string) => {
  return new Error(`Allowance of ${ tokenName } is too low.`)
}

const NON_EXISTENT_PAIR = (): Error => {
  return new Error(`This pair does not exist.`)
}


// ROUTER
const EXPIRED = (): Error => {
  return new Error(`The deadline for this transaction has been surpassed.`)
}

const FORBIDDEN = (): Error => {
  return new Error(`The function called is forbidden.`)
}

const EXCESSIVE_INPUT_AMOUNT = (tokenFrom: string): Error => {
  return new Error(`Amount ${ tokenFrom } being transferred is greater than max amount in.`)
}

const EXCESSIVE_OUTPUT_AMOUNT = (tokenTo: string): Error => {
  return new Error(`Amount ${ tokenTo } being transferred is less than min amount out.`)
}

const INVALID_PATH = (): Error => {
  return new Error(`Either start or end of multi-hop transaction must be Wrapped FSN.`)
}

const SAFE_TRANSFER_FAILED = (): Error => {
  return new Error(`Safe transfer failed.`)
}

const INSUFFICIENT_A_AMOUNT = (tokenA: string): Error => {
  return new Error(`The amount of ${ tokenA } being added/removed is less than the minimum accepted amount.`)
}

const INSUFFICIENT_B_AMOUNT = (tokenB: string): Error => {
  return new Error(`The amount of ${ tokenB } being added/removed is less than the minimum accepted amount.`)
}


// FACTORY
const IDENTICAL_ADDRESSES = (): Error => {
  return new Error(`Tokens in new pair must have different addresses from each other.`)
}

const ZERO_ADDRESS = (): Error => {
  return new Error(`Tokens in new pair cannot be zero address.`)
}

const PAIR_EXISTS = (symbols: Symbols): Error => {
  return new Error(`The pair ${ symbols.symbolA } / ${ symbols.symbolB } already exists.`)
}


// PAIR
const BALANCE_OVERFLOW = (): Error => {
  return new Error(`Balance overflow.`)
}

const INSUFFICIENT_INPUT_AMOUNT = (): Error => {
  return new Error(`Swap input amount is zero.`)
}

const INSUFFICIENT_LIQUIDITY = (): Error => {
  return new Error(`Insufficient liquidity available for this swap.`)
}

const INSUFFICIENT_LIQUIDITY_BURNED = (): Error => {
  return new Error(`One of the token amounts being removed is zero.`)
}

const INSUFFICIENT_LIQUIDITY_MINTED = (): Error => {
  return new Error(`Liquidity amount being added is zero.`)
}

const INSUFFICIENT_OUTPUT_AMOUNT = (): Error => {
  return new Error(`Swap output amount is zero.`)
}

const INVALID_K = (): Error => {
  return new Error(`Invalid constant product.`)
}

const INVALID_TO = (): Error => {
  return new Error(`Swap receiver is one of the tokens in the pool.`)
}

// VAULT
const INVALID_TERM_END = (): Error => {
  return new Error(`Burn expiry date is not valid.`)
}



const DEFAULT = (): Error => {
  return new Error(`Unknown error occurred.`)
}

const DEFAULT_METAMASK_ERROR = (): string => {
  return `Unknown MetaMask error occurred.`
}



const getErrorFragment = (iface: any, errorData: string): string | undefined => {
  const errorList = iface.errors
  const keys = Object.keys(errorList)
  for(let i = 0; i < keys.length; i++) {
    const sighash = iface.getSighash(keys[ i ])
    if(sighash === errorData) {
      return keys[ i ]
    }
  }
}

const getMetamaskError = (code: number): string => {
  switch(code) {
    case 4001:
      return `Request rejected.`
    case 4100:
      return `Request rejected.`
    case 4200:
      return `Action not supported by this provider.`
    case 4900:
      return `Provider disconnected.`
    case 4901:
      return `Provider disconnected from chain.`
    case 32700:
      return `Invalid JSON request.`
    case 32600:
      return `Invalid JSON request.`
    case 32601:
      return `This method does not exist.`
    case 32602:
      return `Invaild method parameters.`
    // case 32603:
    //   return `Unknown error occurred. (JSON-RPC Error).`
    case 32000:
      return `Invalid input.`
    case 32001:
      return `Resource not found.`
    case 32002:
      return `Resource not available.`
    case 32003:
      return `Transaction rejected.`
    case 32004:
      return `Method not supported.`
    case 32005:
      return `Request limit exceeded.`
    default:
      return DEFAULT_METAMASK_ERROR()
  }
}

const getRouterError = (errorFragment: string, symbols: Symbols): Error => {
  switch(errorFragment) {
    case "Expired()":
      return EXPIRED()
    case "Forbidden()":
      return FORBIDDEN()
    case "ExcessiveInputAmount()":
      return EXCESSIVE_INPUT_AMOUNT(symbols.symbolA)
    case "InsufficientAAmount()":
      return INSUFFICIENT_A_AMOUNT(symbols.symbolA)
    case "InsufficientBAmount()":
      return INSUFFICIENT_B_AMOUNT(symbols.symbolB)
    case "ExcessiveOutputAmount()":
      return EXCESSIVE_OUTPUT_AMOUNT(symbols.symbolB)
    case "InvalidPath()":
      return INVALID_PATH()
    case "SafeTransferFailed()":
      return SAFE_TRANSFER_FAILED()
    default:
      return DEFAULT()
  }
}

const getFactoryError = (errorFragment: string, symbols: Symbols): Error => {
  switch(errorFragment) {
    case "IdenticalAddresses()":
      return IDENTICAL_ADDRESSES()
    case "ZeroAddress()":
      return ZERO_ADDRESS()
    case "PairExists()":
      return PAIR_EXISTS(symbols)
    case "Forbidden()":
      return FORBIDDEN()
    default:
      return DEFAULT()
  }
}

const getPairError = (errorFragment: string, symbols: Symbols): Error => {
  switch(errorFragment) {
    case "Forbidden()":
      return FORBIDDEN()
    case "BalanceOverflow()":
      return BALANCE_OVERFLOW()
    case "InsufficientInputAmount()":
      return INSUFFICIENT_INPUT_AMOUNT()
    case "InsufficientLiquidity()":
      return INSUFFICIENT_LIQUIDITY()
    case "InsufficientLiquidityBurned()":
      return INSUFFICIENT_LIQUIDITY_BURNED()
    case "InsufficientLiquidityMinted()":
      return INSUFFICIENT_LIQUIDITY_MINTED()
    case "InsufficientOutputAmount()":
      return INSUFFICIENT_OUTPUT_AMOUNT()
    case "InvalidK()":
      return INVALID_K()
    case "InvalidTo()":
      return INVALID_TO()
    case "TransferFailed()":
      return SAFE_TRANSFER_FAILED()
    default:
      return DEFAULT()
  }
}

const getVaultError = (errorFragment: string, symbols: Symbols): Error => {
  switch(errorFragment) {
    case "IdenticalAddresses()":
      return IDENTICAL_ADDRESSES()
    case "InvalidTermEnd()":
      return INVALID_TERM_END()
    case "ZeroAddress()":
      return ZERO_ADDRESS()
    default:
      return DEFAULT()
  }
}

const getError = (provider: Web3Provider, chainId: number, err: Error, symbols: Symbols): Error | string => {
  const serialized = serializeError(err)

  if(serialized.code !== -32603) return "FREEMOON: " + getMetamaskError(serialized.code)

  const originalError: any = serialized.data
  console.log(originalError)
  const errorData: string = originalError.originalError.data

  const abis = [ routerAbi, factoryAbi, pairAbi, vaultAbi ]
  const getContractError = [ getRouterError, getFactoryError, getPairError, getVaultError ]
 
  let iface, errorFragment

  for(let i = 0; i < abis.length; i++) {
    iface = new ethers.utils.Interface(abis[ i ])
    errorFragment = getErrorFragment(iface, errorData)
    if(errorFragment) return getContractError[ i ](errorFragment, symbols)
  }

  console.log(errorData)

  return DEFAULT()
}


export {
  WEB3_MISSING,
  UNDEFINED_CHAINID,
  UNSUPPORTED_CHAIN,
  FAILED_APPROVAL,
  ZERO_BAL,
  ALLOWANCE_LOW,
  NON_EXISTENT_PAIR,
  getError
}
