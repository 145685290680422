
interface Network {
  chainId: number,
  name: string,
  explorer: string,
  rpcUrl: string,
  nativeCurrency: {
    name: string,
    symbol: string,
    gasToLeave: number
  },
  contracts: {
    routerAddr: string,
    factoryAddr: string,
    vaultAddr: string,
    wfsnAddr: string
  }
}

const networks: Network[] = [
  {
    chainId: 32659,
    name: "Fusion Mainnet",
    explorer: "https://fsnex.com/address/",
    rpcUrl: "https://mainway.freemoon.xyz/gate",
    nativeCurrency: {
      name: "Fusion",
      symbol: "FSN",
      gasToLeave: 0.05
    },
    contracts: {
      routerAddr: "0x936c75f9363c2b7b88feb3e56402d1e4c687734b",
      factoryAddr: "0x43958b7b6bdc198fe2381049e2a408081269bdfc",
      vaultAddr: "0xd76770a123217ba1d3827d3d7f8d45c38b7a148e",
      wfsnAddr: "0x0c05c5710af74d36b4d3bd5460475c20ceca8fe3"
    }
  },
  {
      chainId: 46688,
      name: "Fusion Testnet",
      explorer: "https://fsnex.com/address/",
      rpcUrl: "https://testway.freemoon.xyz/gate",
      nativeCurrency: {
        name: "Fusion",
        symbol: "FSN",
        gasToLeave: 0.05
      },
      contracts: {
        routerAddr: "0x74bc68f90e3c296ec448b2b2743a6421d36a5663",
        factoryAddr: "0x3a0016cee85c267e760e696ae703232f92960beb",
        vaultAddr: "0x26d0827e98d4d3a07c35cb79fd1650ea32a01e53",
        wfsnAddr: "0xbb375120dad0c7ce8b0a2a26ecf7cc5c919c0779"
      }
  },
  {
      chainId: 1337,
      name: "Local Node",
      explorer: "",
      rpcUrl: "http://localhost:8545/",
      nativeCurrency: {
        name: "Fusion",
        symbol: "FSN",
        gasToLeave: 0.5
      },
      contracts: {
        routerAddr: "0x9fe46736679d2d9a65f0992f2272de9f3c7fa6e0",
        factoryAddr: "0xe7f1725e7734ce288f8367e1bb143e90bb3f0512",
        vaultAddr: "",
        wfsnAddr: "0x5fbdb2315678afecb367f032d93f642f64180aa3"
      }
  }
]

const nullNetwork: Network = {
  chainId: 0,
  name: "",
  explorer: "",
  rpcUrl: "",
  nativeCurrency: {
    name: "",
    symbol: "",
    gasToLeave: 0
  },
  contracts: {
    routerAddr: "",
    factoryAddr: "",
    vaultAddr: "",
    wfsnAddr: ""
  }
}


export default networks
export { nullNetwork }
export type { Network }
