
import React, { useState, useEffect, useCallback, Dispatch, SetStateAction } from "react"
import { BigNumber } from "ethers"
import { useWeb3React } from "@web3-react/core"

import { Units, parseUnits, formatUnits, ZERO, checkIsShortEnough, tsToTime, significantDigits } from "../utils/web2Utils"
import { GasFees, NetAmounts, Net, Status, StatusCode, checkIfNeedsApproval, getTokenBal, checkIfPairExists, quote, TxConfig, getGasFees, calculateNetSlippage, deductGasFee, Reserves, getReserves, getCurrentPrice, hideIdenticalTokens } from "../utils/web3Utils"

import { approveRouterAll, addLiquidity } from "../utils/interactions"
import { TokenOnNetwork, defaultToken, Pair, LP_DECIMALS } from "../utils/tokens"

import Picker from "../components/Picker"
import TxConfigure from "../components/TxConfigure"
import StatusMessage from "../components/StatusMessage"

import { Theme } from "../theme"
import { DropDownIcon, SettingsIcon, Title, MainRow, BottomRow, InputToken, InputAmount, BalanceRow, ApproveButton, TokenBalance, SettingsSpacer, ConfirmSpacer, SettingsButton, ConfirmButton, InfoDisplay, InfoDisplayRow, InfoDisplayKey, InfoDisplayData, Icon, ApprovalLoader, ConfirmLoader, InfoDisplayContainer } from "../component-styles"


interface Props {
  tokens: Pair | null,
  setTokens: Dispatch<SetStateAction<Pair | null>>
}


const AddLiquidity: React.FC<Props> = ({ tokens, setTokens }) => {
  const [ tokenA, setTokenA ] = useState<TokenOnNetwork>(defaultToken)
  const [ tokenB, setTokenB ] = useState<TokenOnNetwork>(defaultToken)

  const [ amountA, setAmountA ] = useState<string | null>(null)
  const [ amountB, setAmountB ] = useState<string | null>(null)
  const [ balA, setBalA ] = useState<BigNumber | null>(null)
  const [ balB, setBalB ] = useState<BigNumber | null>(null)

  const [ reserves, setReserves ] = useState<Reserves>({ reserveA: ZERO, reserveB: ZERO })

  const [ gasPriceOptions, setGasPriceOptions ] = useState<GasFees>({ low: ZERO, medium: ZERO, high: ZERO })
  const [ txConfig, setTxConfig ] = useState<TxConfig>({ slippage: "0", gasPrice: "0", deadline: "0" })

  const [ price, setPrice ] = useState<BigNumber | null>(null)

  const [ pairExists, setPairExists ] = useState<boolean>(false)

  const [ netSlippage, setNetSlippage ] = useState<NetAmounts>({ tokenA: ZERO, tokenB: ZERO })

  const [ status, setStatus ] = useState<Status | null>(null)

  const [ approvalALoading, setApprovalALoading ] = useState<boolean>(false)
  const [ approvalBLoading, setApprovalBLoading ] = useState<boolean>(false)
  const [ confirmLoading, setConfirmLoading ] = useState<boolean>(false)

  const [ displayAPicker, setDisplayAPicker ] = useState<boolean>(false)
  const [ displayBPicker, setDisplayBPicker ] = useState<boolean>(false)
  const [ displayApprovalA, setDisplayApprovalA ] = useState<boolean>(false)
  const [ displayApprovalB, setDisplayApprovalB ] = useState<boolean>(false)
  const [ displayTxConfig, setDisplayTxConfig ] = useState<boolean>(false)
  const [ displayConfirm, setDisplayConfirm ] = useState<boolean>(false)



  const { provider, chainId, accounts } = useWeb3React()



  const getGasOptions = useCallback(async () => {
    if(!provider) return
    const { low, medium, high } = await getGasFees(provider)
    setGasPriceOptions({ low, medium, high })
    setTxConfig({ slippage: "1", gasPrice: formatUnits(medium, Units.gwei), deadline: "5" })
  }, [ provider ])

  const refreshReserves = useCallback(async () => {
    if(!provider || !chainId) return
    if(displayAPicker || displayBPicker) return
    if(tokenA.address && tokenB.address && pairExists) {
      console.log(`Refreshing Add Liquidity Reserves. ${ tsToTime() }`)
      const currentReserves = await getReserves(provider, chainId, tokenA.address, tokenB.address)
      const currentPrice = getCurrentPrice(currentReserves, tokenA.decimals, tokenB.decimals)
      setReserves(currentReserves)
      setPrice(currentPrice)
    } else {
      setReserves({ reserveA: ZERO, reserveB: ZERO })
    }
  }, [ provider, chainId, tokenA, tokenB, pairExists, displayAPicker, displayBPicker ])

  const refreshBalanceA = useCallback(async () => {
    if(!provider || !accounts) return
    setAmountA(null)
    setBalA(null)
    if(tokenA.address) {
      const tokenABal = await getTokenBal(provider, accounts, tokenA.address)
      setBalA(tokenABal)
    }
  }, [ provider, accounts, tokenA ])

  const refreshBalanceB = useCallback(async () => {
    if(!provider || !accounts) return
    setAmountB(null)
    setBalB(null)
    if(tokenB.address) {
      const tokenBBal = await getTokenBal(provider, accounts, tokenB.address)
      setBalB(tokenBBal)
    }
  }, [ provider, accounts, tokenB ])

  const checkAllowanceA = useCallback(async () => {
    if(!provider || !chainId || !accounts) return
    if(tokenA.address) {
      const needsApprovalA = await checkIfNeedsApproval(provider, chainId, accounts, tokenA.address)
      setDisplayApprovalA(needsApprovalA)
    }
  }, [ provider, chainId, accounts, tokenA ])

  const checkAllowanceB = useCallback(async () => {
    if(!provider || !chainId || !accounts) return
    if(tokenB.address) {
      const needsApprovalB = await checkIfNeedsApproval(provider, chainId, accounts, tokenB.address)
      setDisplayApprovalB(needsApprovalB)
    }
  }, [ provider, chainId, accounts, tokenB ])

  const checkPair = useCallback(async () => {
    if(!provider || !chainId) return
    setPairExists(false)
    if(tokenA.address && tokenB.address) {
      const pairDoesExist = await checkIfPairExists(provider, chainId, tokenA.address, tokenB.address)
      setPairExists(pairDoesExist)
    }
  }, [ provider, chainId, tokenA, tokenB ])

  const updateNetSlippage = useCallback(() => {
    if(tokenA.address && tokenB.address && amountA && amountB) {
      const amountAWei = parseUnits(amountA, tokenA.decimals)
      const amountBWei = parseUnits(amountB, tokenB.decimals)
      let minTokenAReceived = amountAWei
      let minTokenBReceived = amountBWei
      if(pairExists) {
        minTokenAReceived = calculateNetSlippage(amountAWei, txConfig.slippage, Net.Min)
        minTokenBReceived = calculateNetSlippage(amountBWei, txConfig.slippage, Net.Min)
      }
      const minADeducted = minTokenAReceived
      const minBDeducted = minTokenBReceived
      setNetSlippage({ tokenA: minADeducted, tokenB: minBDeducted })
    } else {
      setNetSlippage({ tokenA: ZERO, tokenB: ZERO })
    }
  }, [ tokenA, tokenB, amountA, amountB, pairExists, txConfig.slippage ])

  const checkConfirmActive = useCallback(() => {
    setDisplayConfirm(Boolean(tokenA.address && tokenB.address && amountA && amountB && !netSlippage.tokenA.isZero() && !netSlippage.tokenB.isZero() && !displayApprovalA && !displayApprovalB))
  }, [ tokenA, tokenB, amountA, amountB, netSlippage, displayApprovalA, displayApprovalB ])



  const handlePickerAClick = () => {
    if(!displayBPicker) setDisplayAPicker(true)
  }

  const handlePickerBClick = () => {
    if(!displayAPicker) setDisplayBPicker(true)
  }

  const handleApproveAClick = async () => {
    if(!provider || !chainId) return
    if(!tokenA.address) return
    try {
      setApprovalALoading(true)
      await approveRouterAll(provider, chainId, tokenA.address)
      setApprovalALoading(false)
      await checkAllowanceA()
    } catch(err: any) {
      setStatus({ msg: err.message, code: StatusCode.Fail })
      setApprovalALoading(false)
      setDisplayApprovalA(true)
    }
  }

  const handleApproveBClick = async () => {
    if(!provider || !chainId) return
    if(!tokenB.address) return
    try {
      setApprovalBLoading(true)
      await approveRouterAll(provider, chainId, tokenB.address)
      setApprovalBLoading(false)
      await checkAllowanceB()
    } catch(err: any) {
      setStatus({ msg: err.message, code: StatusCode.Fail })
      setApprovalBLoading(false)
      setDisplayApprovalB(true)
    }
  }

  const handleAmountAChange = async (amount: string) => {
    if(!provider || !chainId) return

    const isShortEnough = checkIsShortEnough(amount)

    if(isShortEnough) {
      if(amount) {
        setAmountA(amount)
        if(pairExists && amount && (Number(amount) !== 0)) {
          const amountAWei = parseUnits(amount, tokenA.decimals)
          const amountBEquivWei = await quote(provider, chainId, amountAWei, tokenA.address, tokenB.address, reserves)
          const amountBEquiv = formatUnits(amountBEquivWei, tokenB.decimals)
          setAmountB(amountBEquiv)
        }
      } else {
        setAmountA(null)
        setAmountB(null)
      }
    }
  }

  const handleAmountBChange = async (amount: string) => { 
    if(!provider || !chainId) return

    const isShortEnough = checkIsShortEnough(amount)

    if(isShortEnough) {
      if(amount) {
        setAmountB(amount)
        if(pairExists && amount && (Number(amount) !== 0)) {
          const amountBWei = parseUnits(amount, tokenB.decimals)
          const amountAEquivWei = await quote(provider, chainId, amountBWei, tokenB.address, tokenA.address, { reserveA: reserves.reserveB, reserveB: reserves.reserveA })
          const amountAEquiv = formatUnits(amountAEquivWei, tokenA.decimals)
          setAmountA(amountAEquiv)
        }
      } else {
        setAmountA(null)
        setAmountB(null)
      }
    }
  }

  const handleMaxAClick = () => {
    if(!chainId) return
    if(!balA) {
      setAmountA(null)
      return
    }
    const maxA = deductGasFee(chainId, tokenA.address, balA)
    const maxAPositive = maxA.lt(ZERO) ? ZERO : maxA
    const balAString = formatUnits(maxAPositive, tokenA.decimals)
    handleAmountAChange(balAString)
  }

  const handleMaxBClick = () => {
    if(!chainId) return
    if(!balB) {
      setAmountB(null)
      return
    }
    const maxB = deductGasFee(chainId, tokenB.address, balB)
    const maxBPositive = maxB.lt(ZERO) ? ZERO : maxB
    const balBString = formatUnits(maxBPositive, tokenB.decimals)
    handleAmountBChange(balBString)
  }

  const handleSettingsClick = () => {
    setDisplayTxConfig(true)
  }

  const handleConfirmClick = async () => {
    if(!provider || !chainId || !accounts) return
    if(displayConfirm && amountA && amountB && !displayApprovalA && !displayApprovalB) {
      const amountAWei = parseUnits(amountA, tokenA.decimals)
      const amountBWei = parseUnits(amountB, tokenB.decimals)

      if(balA && amountAWei.gt(balA)) {
        setStatus({ msg: `Amount ${ tokenA.symbol } exceeds balance.`, code: StatusCode.Warn })
        setDisplayConfirm(true)
      } else if(balB && amountBWei.gt(balB)) {
        setStatus({ msg: `Amount ${ tokenB.symbol } exceeds balance.`, code: StatusCode.Warn })
        setDisplayConfirm(true)
      } else {
        setConfirmLoading(true)
        setDisplayConfirm(false)
        try {
          const tx = await addLiquidity(provider, chainId, accounts, tokenA.address, tokenB.address, amountAWei, amountBWei, txConfig, netSlippage, pairExists)
          const { returnValues, receipt } = tx ? tx : { returnValues: "", receipt: "" }
          const [ amountAAdded, amountBAdded, liquidityMinted ] = returnValues && returnValues.length ? returnValues : [ ZERO, ZERO, ZERO ]
          const txResult = (!amountAAdded.isZero() && !amountBAdded.isZero() && !liquidityMinted.isZero()
            ? { msg: `Added ${ significantDigits(formatUnits(amountAAdded, tokenA.decimals), 8) } ${ tokenA.symbol }, ${ significantDigits(formatUnits(amountBAdded, tokenB.decimals), 8) } ${ tokenB.symbol }, liquidity minted: ${ significantDigits(formatUnits(liquidityMinted, LP_DECIMALS), 8) }.`, code: StatusCode.Success, tx: receipt.transactionHash }
            : null
          )

          setConfirmLoading(false)
          setAmountA(null)
          setAmountB(null)
          setStatus(txResult)
          refreshBalanceA()
          refreshBalanceB()
        } catch(err: any) {
          setStatus({ msg: err.message, code: StatusCode.Fail })
          setConfirmLoading(false)
          setDisplayConfirm(true)
        }
      }
    }
  }



  useEffect(() => {
    if(tokens) {
      setTokenA(tokens.tokenA)
      setTokenB(tokens.tokenB)
    }

    return () => setTokens(null)
  }, [ tokens, setTokens ])

  useEffect(() => {
    getGasOptions()
  }, [ getGasOptions ])

  useEffect(() => {
    refreshReserves()
    const refreshInterval = setInterval(() => refreshReserves(), 15000)
    return () => clearInterval(refreshInterval)
  }, [ refreshReserves ])


  useEffect(() => {
    refreshBalanceA()
    checkAllowanceA()
  }, [ refreshBalanceA, checkAllowanceA ])

  useEffect(() => {
    refreshBalanceB()
    checkAllowanceB()
  }, [ refreshBalanceB, checkAllowanceB ])

  useEffect(() => {
    checkPair()
  }, [ checkPair ])

  useEffect(() => {
    updateNetSlippage()
  }, [ updateNetSlippage ])

  useEffect(() => {
    checkConfirmActive()
  }, [ checkConfirmActive ])



  return (
    <>
      <Title theme={ Theme }>ADD</Title>

      <BalanceRow>
        <ApproveButton onClick={ () => !approvalALoading && !approvalBLoading && !confirmLoading ? handleApproveAClick() : null } theme={ Theme } isActive={ displayApprovalA }>{ !approvalALoading ? "APPROVE" : <><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/></> }</ApproveButton>
        <TokenBalance onClick={ handleMaxAClick } theme={ Theme } isActive={ Boolean(balA) }>{ balA && balA.isZero() ? "0.0" : formatUnits(balA, tokenA.decimals) }</TokenBalance>
      </BalanceRow>

      <MainRow isBottom={ false }>
        <InputToken onClick={ handlePickerAClick } theme={ Theme } isSelected={ Boolean(tokenA.address) }><Icon src={ tokenA.logo } size={ "30" }/>{ tokenA.symbol }<DropDownIcon/></InputToken>
        <InputAmount type="number" step="any"  min="0" placeholder={ "0.0" } value={ amountA ? amountA : "" } onChange={ e => handleAmountAChange(e.target.value) } theme={ Theme }/>
      </MainRow>

      <BalanceRow>
        <ApproveButton onClick={ () => !approvalALoading && !approvalBLoading && !confirmLoading ? handleApproveBClick() : null } theme={ Theme } isActive={ displayApprovalB }>{ !approvalBLoading ? "APPROVE" : <><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/></> }</ApproveButton>
        <TokenBalance onClick={ handleMaxBClick } theme={ Theme } isActive={ Boolean(balB) }>{ balB && balB.isZero() ? "0.0" : significantDigits(formatUnits(balB, tokenB.decimals), 8) }</TokenBalance>
      </BalanceRow>

      <MainRow isBottom={ false }>
        <InputToken onClick={ handlePickerBClick } theme={ Theme } isSelected={ Boolean(tokenB.address) }><Icon src={ tokenB.logo } size={ "30" }/>{ tokenB.symbol }<DropDownIcon/></InputToken>
        <InputAmount type="number" step="any" min="0" placeholder={ "0.0" } value={ amountB ? amountB : "" } onChange={ e => handleAmountBChange(e.target.value) } theme={ Theme }/>
      </MainRow>

      <BottomRow>
        <SettingsSpacer>
          <SettingsButton onClick={ handleSettingsClick } theme={ Theme }><SettingsIcon/></SettingsButton>
        </SettingsSpacer>
        <ConfirmSpacer>
          <ConfirmButton onClick={ () => !approvalALoading && !approvalBLoading && !confirmLoading ? handleConfirmClick() : null } theme={ Theme } isActive={ Boolean(displayConfirm && !confirmLoading) }>{ !confirmLoading ? "Add" : <><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/></> }</ConfirmButton>
        </ConfirmSpacer>
      </BottomRow>

      <InfoDisplay theme={ Theme } isActive={ Boolean(pairExists && price && !price.isZero()) }>
        <InfoDisplayContainer theme={ Theme }>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Quote Price per { tokenB.symbol }</InfoDisplayKey><InfoDisplayData>{ price ? significantDigits(formatUnits(price, tokenA.decimals), 8) : "0000.0000" } { tokenA.symbol }</InfoDisplayData></InfoDisplayRow>
        </InfoDisplayContainer>
      </InfoDisplay>

      <InfoDisplay theme={ Theme } isActive={ Boolean(!netSlippage.tokenA.isZero() && !netSlippage.tokenB.isZero()) }>
        <InfoDisplayContainer theme={ Theme }>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Minimum { tokenA.symbol } Deposited</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(netSlippage.tokenA, tokenA.decimals), 8) } { tokenA.symbol }</InfoDisplayData></InfoDisplayRow>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Minimum { tokenB.symbol } Deposited</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(netSlippage.tokenB, tokenB.decimals), 8) } { tokenB.symbol }</InfoDisplayData></InfoDisplayRow>
        </InfoDisplayContainer>
      </InfoDisplay>

      <InfoDisplay theme={ Theme } isActive={ Boolean(!netSlippage.tokenA.isZero() && !netSlippage.tokenB.isZero()) }>
        <InfoDisplayContainer theme={ Theme }>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Protocol Fee</InfoDisplayKey><InfoDisplayData>0.2%</InfoDisplayData></InfoDisplayRow>
        </InfoDisplayContainer>
      </InfoDisplay>

      {
        displayAPicker && chainId
          ? <Picker onClose={ () => setDisplayAPicker(false) } unavailable={ hideIdenticalTokens(chainId, tokenB.address) } setPick={ setTokenA } title="Select Token A"/>
          : ""
      }

      {
        displayBPicker && chainId
          ? <Picker onClose={ () => setDisplayBPicker(false) } unavailable={ hideIdenticalTokens(chainId, tokenA.address) } setPick={ setTokenB } title="Select Token B"/>
          : ""
      }

      {
        displayTxConfig 
        ? <TxConfigure onClose={ () => setDisplayTxConfig(false) } txConfig={ txConfig } setTxConfig={ setTxConfig } gasPriceOptions={ gasPriceOptions }/>
        : ""
      }

      {
        status && status.msg
          ? <StatusMessage onClose={ () => setStatus(null) } status={ status }/>
          : ""
      }
    </>
  )
}

export default AddLiquidity
