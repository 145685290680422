
import React, { useState, useEffect, useCallback, Dispatch, SetStateAction } from "react"
import { BigNumber } from "ethers"
import { useWeb3React } from "@web3-react/core"

import { Units, parseUnits, formatUnits, significantDigits, ZERO, tsToTime, tsToDate } from "../utils/web2Utils"
import { Status, Balances, RemovableLiquidity, checkIfBurnNeedsApproval, getLPBalance, checkIfPairExists, getLiquiditySliceAmounts, calculateRemovableLiquidity, StatusCode, getPairBalances, getTermDate, TotalBurned, Term } from "../utils/web3Utils"

import { approveVaultAllLP, burnLiquiditySlice } from "../utils/interactions"
import { Pair, TokenOnNetwork, defaultToken, LP_DECIMALS } from "../utils/tokens"

import Picker from "../components/Picker"
import StatusMessage from "../components/StatusMessage"

import { Theme } from "../theme"
import { DropDownIcon, Title, MainRow, InputToken, InputAmount, InputAmountPlaceholder, BalanceRow, ApproveButton, TokenBalance, RowSpacer, RemoveButton, InfoDisplay, InfoDisplayRow, InfoDisplayKey, InfoDisplayData, Icon, ApprovalLoader, ConfirmLoader, InfoDisplayContainer } from "../component-styles"


interface Props {
  tokens: Pair | null,
  setTokens: Dispatch<SetStateAction<Pair | null>>
}


const Vault: React.FC<Props> = ({ tokens, setTokens }) => {
  const [ tokenA, setTokenA ] = useState<TokenOnNetwork>(defaultToken)
  const [ tokenB, setTokenB ] = useState<TokenOnNetwork>(defaultToken)
 
  const [ amount, setAmount ] = useState<string | null>(null)
  const [ bal, setBal ] = useState<BigNumber | null>(null)

  const [ pairBals, setPairBals ] = useState<Balances>({ balA: ZERO, balB: ZERO })

  const [ removableLiquidity, setRemovableLiquidity ] = useState<RemovableLiquidity>({ amountA: ZERO, amountB: ZERO })

  const [ termEnd, setTermEnd ] = useState<string | null>(null)

  const [ pairExists, setPairExists ] = useState<boolean>(false)

  const [ totalBurned, setTotalBurned ] = useState<TotalBurned>({ liquiditySliceAmountBurned: ZERO, burnedBy: ZERO })

  const [ status, setStatus ] = useState<Status | null>(null)

  const [ approvalLoading, setApprovalLoading ] = useState<boolean>(false)
  const [ confirmLoading, setConfirmLoading ] = useState<boolean>((false))

  const [ displayAPicker, setDisplayAPicker ] = useState<boolean>(false)
  const [ displayBPicker, setDisplayBPicker ] = useState<boolean>(false)
  const [ displayApproval, setDisplayApproval ] = useState<boolean>(false)
  const [ displayConfirm, setDisplayConfirm ] = useState<boolean>(false)



  const { provider, chainId, accounts } = useWeb3React()



  const getEndDate = useCallback(async () => {
    if(!provider || !chainId) return
    const endDate = await getTermDate(provider, chainId, Term.Long)
    setTermEnd(endDate)
  }, [ provider, chainId ])

  const refreshPairBalances = useCallback(async () => {
    if(!provider || !chainId) return
    if(displayAPicker || displayBPicker) return
    if(tokenA.address && tokenB.address) {
      console.log(`Refreshing Burn Liquidity Pair Balances. ${ tsToTime() }`)
      const currentBals = await getPairBalances(provider, chainId, tokenA.address, tokenB.address)
      setPairBals(currentBals)
    }
  }, [ provider, chainId, tokenA, tokenB, displayAPicker, displayBPicker ])

  const refreshBalance = useCallback(async () => {
    if(!provider || !chainId || !accounts) return
    setAmount(null)
    setBal(null)
    if(tokenA.address && tokenB.address) {
      const lpBal = await getLPBalance(provider, chainId, accounts, tokenA.address, tokenB.address)
      setBal(lpBal)
    }
  }, [ provider, chainId, accounts, tokenA, tokenB ])

  const checkAllowance = useCallback(async () => {
    if(!provider || !chainId || !accounts) return
    if(tokenA.address && tokenB.address) {
      const needsApprovalA = await checkIfBurnNeedsApproval(provider, chainId, accounts, tokenA.address, tokenB.address)
      setDisplayApproval(needsApprovalA)
    }
  }, [ provider, chainId, accounts, tokenA, tokenB ])

  const checkPair = useCallback(async () => {
    if(!provider || !chainId || !accounts) return
    setPairExists(false)
    if(tokenA.address && tokenB.address && termEnd) {
      const pairDoesExist = await checkIfPairExists(provider, chainId, tokenA.address, tokenB.address)
 
      if(pairDoesExist) {
        const { liquiditySliceAmountBurned, burnedBy } = await getLiquiditySliceAmounts(provider, chainId, accounts, tokenA.address, tokenB.address, termEnd)
        setTotalBurned({ liquiditySliceAmountBurned, burnedBy })
      } else {
        setTotalBurned({ liquiditySliceAmountBurned: ZERO, burnedBy: ZERO })
      }

      setPairExists(pairDoesExist)
    }
  }, [ provider, chainId, accounts, tokenA, tokenB, termEnd ])

  const checkRemovableLiquidity = useCallback(async () => {
    if(!provider || !chainId) return
    if(tokenA.address && tokenB.address && amount && pairExists && (Number(amount) !== 0) && !pairBals.balA.isZero() && !pairBals.balB.isZero()) {
      const liquidityRemovable = await calculateRemovableLiquidity(provider, chainId, tokenA.address, tokenB.address, amount, pairBals)
      setRemovableLiquidity(liquidityRemovable)
    } else {
      setRemovableLiquidity({ amountA: ZERO, amountB: ZERO })
    }
  }, [ provider, chainId, tokenA, tokenB, amount, pairExists, pairBals ])

  const checkConfirmActive = useCallback(() => {
    setDisplayConfirm(Boolean(tokenA.address && tokenB.address && amount && !removableLiquidity.amountB.isZero() && !removableLiquidity.amountB.isZero() && pairExists && !displayApproval))
  }, [ tokenA, tokenB, amount, removableLiquidity, pairExists, displayApproval ])



  const handlePickerAClick = () => {
    if(!displayBPicker) setDisplayAPicker(true)
  }

  const handlePickerBClick = () => {
    if(!displayAPicker) setDisplayBPicker(true)
  }

  const handleApproveClick = async () => {
    if(!provider || !chainId) return
    if(!tokenA.address || !tokenB.address) return
    try {
      setApprovalLoading(true)
      await approveVaultAllLP(provider, chainId, tokenA.address, tokenB.address)
      setApprovalLoading(false)
      await checkAllowance()
    } catch(err: any) {
      setStatus({ msg: err.message, code: StatusCode.Fail })
      setApprovalLoading(false)
      setDisplayApproval(true)
    }
  }

  const handleAmountChange = async (amount: string) => {
    if(!amount) {
      setAmount(null)
      return
    }
    setAmount(amount)
  }

  const handleMaxClick = () => {
    if(!bal) {
      setAmount(null)
      return
    }
    const balString = formatUnits(bal, Units.ether)
    handleAmountChange(balString)
  }

  const handleConfirmClick = async () => {
    if(!provider || !chainId || !accounts) return
    if(displayConfirm && amount && !displayApproval && termEnd) {
      const amountWei = parseUnits(amount, Units.ether)

      if(bal && amountWei.gt(bal)) {
        setStatus({ msg: `Amount ${ tokenA.symbol }/${ tokenB.symbol } exceeds balance.`, code: StatusCode.Warn })
        setDisplayConfirm(true)
      } else {
        setConfirmLoading(true)
        setDisplayConfirm(false)
        try {
          const receipt = await burnLiquiditySlice(provider, chainId, accounts, tokenA.address, tokenB.address, amountWei, termEnd)
          const txResult = (receipt
            ? { msg: `Burned ${ significantDigits(formatUnits(amountWei, tokenA.decimals), 8) } ${ tokenA.symbol }/${ tokenB.symbol }, until ${ tsToDate(termEnd) }.`, code: StatusCode.Success, tx: receipt.transactionHash }
            : null
          )

          setConfirmLoading(false)
          setAmount(null)
          setStatus(txResult)
          refreshBalance()
          checkPair()
        } catch(err: any) {
          setStatus({ msg: err.message, code: StatusCode.Fail })
          setConfirmLoading(false)
          setDisplayConfirm(true)
        }
      }
    }
  }



  useEffect(() => {
    if(tokens) {
      setTokenA(tokens.tokenA)
      setTokenB(tokens.tokenB)
    }

    return () => setTokens(null)
  }, [ tokens, setTokens ])

  useEffect(() => {
    getEndDate()
  }, [ getEndDate ])

  useEffect(() => {
    refreshPairBalances()
    const refreshInterval = setInterval(() => refreshPairBalances(), 15000)
    return () => clearInterval(refreshInterval)
  }, [ refreshPairBalances ])

  useEffect(() => {
    refreshBalance()
    checkAllowance()
  }, [ refreshBalance, checkAllowance ])

  useEffect(() => {
    checkPair()
  }, [ checkPair ])

  useEffect(() => {
    const updateTimeout = setTimeout(() => checkRemovableLiquidity(), 1000)
    return () => clearTimeout(updateTimeout)
  }, [ checkRemovableLiquidity ])

  useEffect(() => {
    checkConfirmActive()
  }, [ checkConfirmActive ])



  return (
    <div>
      <Title theme={ Theme }>VAULT</Title>

      <RowSpacer size={ "10" }/>

      <BalanceRow>
        <ApproveButton onClick={ () => !approvalLoading && !confirmLoading ? handleApproveClick() : null } theme={ Theme } isActive={ displayApproval }>{ !approvalLoading ? "APPROVE PAIR" : <><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/></> }</ApproveButton>
        <TokenBalance onClick={ handleMaxClick } theme={ Theme } isActive={ Boolean(bal) }>{ bal && bal.isZero() ? "0.0" : significantDigits(formatUnits(bal, LP_DECIMALS), 8) }</TokenBalance>
      </BalanceRow>

      <MainRow isBottom={ false }>
        <InputToken onClick={ handlePickerAClick } theme={ Theme } isSelected={ Boolean(tokenA.address) }><Icon src={ tokenA.logo } size={ "30" }/>{ tokenA.symbol }<DropDownIcon/></InputToken>
        <InputAmount type="number" step="any"  min="0" placeholder={ "0.0" } value={ amount ? amount : "" } onChange={ e => handleAmountChange(e.target.value) } theme={ Theme }/>
      </MainRow>

      <RowSpacer size={ "5" }/>

      <MainRow isBottom={ true }>
        <InputToken onClick={ handlePickerBClick } theme={ Theme } isSelected={ Boolean(tokenB.address) }><Icon src={ tokenB.logo } size={ "30" }/>{ tokenB.symbol }<DropDownIcon/></InputToken>
        <InputAmountPlaceholder><div/><RemoveButton onClick={ handleConfirmClick } theme={ Theme } isActive={ Boolean(displayConfirm && !confirmLoading) }>{ !confirmLoading ? "Add to Vault" : <><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/></> }</RemoveButton></InputAmountPlaceholder>
      </MainRow>

      <InfoDisplay theme={ Theme } isActive={ Boolean(termEnd) }>
        <InfoDisplayContainer theme={ Theme}>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Vault Expiry Date</InfoDisplayKey><InfoDisplayData>{ termEnd ? tsToDate(termEnd) : null }</InfoDisplayData></InfoDisplayRow>
        </InfoDisplayContainer>
      </InfoDisplay>

      <InfoDisplay theme={ Theme } isActive={ Boolean(removableLiquidity && !removableLiquidity.amountA.isZero() && !removableLiquidity.amountB.isZero()) }>
        <InfoDisplayContainer theme={ Theme }>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>{ tokenA.symbol } to be locked</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(removableLiquidity.amountA, tokenA.decimals), 8) } { tokenA.symbol }</InfoDisplayData></InfoDisplayRow>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>{ tokenB.symbol } to be locked</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(removableLiquidity.amountB, tokenB.decimals), 8) } { tokenB.symbol }</InfoDisplayData></InfoDisplayRow>
        </InfoDisplayContainer>
      </InfoDisplay>

      <InfoDisplay theme={ Theme } isActive={ Boolean(pairExists) }>
        <InfoDisplayContainer theme={ Theme }>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Total vault</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(totalBurned.liquiditySliceAmountBurned, LP_DECIMALS), 8) } { tokenA.symbol }/{ tokenB.symbol }</InfoDisplayData></InfoDisplayRow>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Your vault</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(totalBurned.burnedBy, LP_DECIMALS), 8) } { tokenA.symbol }/{ tokenB.symbol }</InfoDisplayData></InfoDisplayRow>
        </InfoDisplayContainer>
      </InfoDisplay>

      {
        displayAPicker
          ? <Picker onClose={ () => setDisplayAPicker(false) } unavailable={ [ tokenB.address ] } setPick={ setTokenA } title="From"/>
          : ""
      }

      {
        displayBPicker
          ? <Picker onClose={ () => setDisplayBPicker(false) } unavailable={ [ tokenA.address ] } setPick={ setTokenB } title="To"/>
          : ""
      }

      {
        status && status.msg
          ? <StatusMessage onClose={ () => setStatus(null) } status={ status }/>
          : ""
      }
    </div>
  )
}

export default Vault
