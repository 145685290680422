
import React, { useState, Dispatch, SetStateAction } from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { useWeb3React } from "@web3-react/core"

import { formatUnits, significantDigits } from "../utils/web2Utils"
import { LPBalance, loadLPBalances, LiquidityType } from "../utils/web3Utils"

import { LP_DECIMALS, Pair } from "../utils/tokens"

import { Theme } from "../theme"
import { Title, LPBalanceList, LPBalanceHeader, LPBalanceItem, LPBalanceSection, LPBalanceItemLoading, NoLPBalances, LPBalanceSearch, LiquidityTabRow, LiquidityTabButton, LiquidityTypeRow, LiquidityTypeTab, ConfirmLoader } from "../component-styles"


const StyledNavLink = styled(NavLink)`
  text-decoration: none;

  @media (max-width: 700px) {
    width: 50%;
  }
`


interface Props {
  tokens: Pair | null,
  setTokens: Dispatch<SetStateAction<Pair | null>>
}


const Liquidity: React.FC<Props> = ({ tokens, setTokens }) => {
  const [ liquidityType, setLiquidityType ] = useState<LiquidityType>(LiquidityType.Spot)
  const [ balances, setBalances ] = useState<LPBalance[] | null>(null)

  const [ balancesLoading, setBalancesLoading ] = useState<boolean>(false)



  const { provider, chainId, accounts } = useWeb3React()



  const getLpBalances = async () => {
    if(!provider || !chainId || !accounts) return
    setBalancesLoading(true)
    const lpBals = await loadLPBalances(provider, chainId, accounts, liquidityType)
    setBalancesLoading(false)
    setBalances(lpBals)
  }



  const toggleTokenSelect = (lpBal: LPBalance) => {
    const tokenAAddr = lpBal.tokenA.address
    const tokenBAddr = lpBal.tokenB.address
    if(!tokens) {
      setTokens({ tokenA: lpBal.tokenA, tokenB: lpBal.tokenB })
    } else {
      const pairSelected = (tokens.tokenA.address === tokenAAddr) && (tokens.tokenB.address === tokenBAddr) 
      const pairSelectedBackwards = (tokens.tokenA.address === tokenBAddr) && (tokens.tokenB.address === tokenAAddr)
      if(!pairSelected && !pairSelectedBackwards) {
        setTokens({ tokenA: lpBal.tokenA, tokenB: lpBal.tokenB })
      } else {
        setTokens(null)
      }
    }
  }

  const displayBalances = () => {
    if(balances && (balances.length !== 0)) {
      return balances.map((lpBal, i) => (
        <LPBalanceItem key={ i } onClick={ () => toggleTokenSelect(lpBal) } theme={ Theme } isActive={ Boolean(tokens && (tokens.tokenA.symbol === lpBal.tokenA.symbol) && (tokens.tokenB.symbol === lpBal.tokenB.symbol)) }>
          <LPBalanceSection>{ lpBal.tokenA.symbol + "/" + lpBal.tokenB.symbol }</LPBalanceSection>
          <LPBalanceSection>{ significantDigits(formatUnits(lpBal.fullBal, LP_DECIMALS), 4) }</LPBalanceSection>
          <LPBalanceSection>{ significantDigits(formatUnits(lpBal.vaultBal, LP_DECIMALS), 4) }</LPBalanceSection>
        </LPBalanceItem>
      ))
    } else {
      return <NoLPBalances onClick={ getLpBalances } theme={ Theme }><LPBalanceSearch theme={ Theme }>Search For LP Balances (Slow)</LPBalanceSearch></NoLPBalances>
    }
  }

  const handleTypeChange = (lType: LiquidityType) => {
    setLiquidityType(lType)
  }



  return (
    <div>
      <Title theme={ Theme }>LIQUIDITY</Title>

      <LiquidityTypeRow>
        <LiquidityTypeTab onClick={ () => !balancesLoading ? handleTypeChange(LiquidityType.Spot) : null } theme={ Theme } isActive={ liquidityType === LiquidityType.Spot }>SPOT</LiquidityTypeTab>
        <LiquidityTypeTab onClick={ () => !balancesLoading ? handleTypeChange(LiquidityType.Futures) : null  } theme={ Theme } isActive={ liquidityType === LiquidityType.Futures }>FUTURES</LiquidityTypeTab>
        <LiquidityTypeTab onClick={ () => !balancesLoading ? handleTypeChange(LiquidityType.Options) : null  } theme={ Theme } isActive={ liquidityType === LiquidityType.Options }>OPTIONS</LiquidityTypeTab>
      </LiquidityTypeRow>

      <LiquidityTabRow>
        <StyledNavLink to="/liquidity/add"><LiquidityTabButton theme={ Theme } isActive={ true }>Add<div>{ tokens ? ` ${ tokens.tokenA.symbol }/${ tokens.tokenB.symbol }` : "" }</div></LiquidityTabButton></StyledNavLink>
        <StyledNavLink to="/liquidity/remove"><LiquidityTabButton theme={ Theme } isActive={ true }>Remove<div>{ tokens ? ` ${ tokens.tokenA.symbol }/${ tokens.tokenB.symbol }` : "" }</div></LiquidityTabButton></StyledNavLink>
        <StyledNavLink to="/liquidity/vault"><LiquidityTabButton theme={ Theme } isActive={ true }>Vault<div>{ tokens ? ` ${ tokens.tokenA.symbol }/${ tokens.tokenB.symbol }` : "" }</div></LiquidityTabButton></StyledNavLink>
      </LiquidityTabRow>

      {
        !balancesLoading && balances && balances.length !== 0
          ? <LPBalanceHeader>
               <LPBalanceSection>Pair</LPBalanceSection>
               <LPBalanceSection>Balance</LPBalanceSection>
               <LPBalanceSection>Vault</LPBalanceSection>
            </LPBalanceHeader>
          : <div/>
      }

      <LPBalanceList theme={ Theme }>
        { !balancesLoading ? displayBalances() : <LPBalanceItemLoading theme={ Theme }><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/></LPBalanceItemLoading> }
      </LPBalanceList>
    </div>
  )
}

export default Liquidity
