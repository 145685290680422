
import React, { useState, useEffect, useCallback } from "react"
import { BigNumber } from "ethers"
import { useWeb3React } from "@web3-react/core"

import { Units, parseUnits, formatUnits, ZERO, tsToTime, checkIsShortEnough, significantDigits } from "../utils/web2Utils"
import { GasFees, TxConfig, Status, Reserves, getGasFees, hideIdenticalTokens, getTokenBal, checkIfNeedsApproval, ExactField, calculateNetSlippage, Net, StatusCode, deductGasFee, getUnavailablePairsForToken, getReserves, getAmountOut, getAmountIn, calculateProviderFee, PriceImpact, getCurrentPrice, calculatePriceImpact } from "../utils/web3Utils"

import { approveRouterAll, swap } from "../utils/interactions"
import { TokenOnNetwork, defaultToken } from "../utils/tokens"

import Picker from "../components/Picker"
import TxConfigure from "../components/TxConfigure"
import StatusMessage from "../components/StatusMessage"

import { Theme } from "../theme"
import { DropDownIcon, SettingsIcon, Title, MainRow, BottomRow, InputToken, InputAmount, BalanceRow, ApproveButton, TokenBalance, SwitchFields, SettingsSpacer, ConfirmSpacer, SettingsButton, ConfirmButton, InfoDisplay, InfoDisplayRow, InfoDisplayKey, InfoDisplayData, Icon, ApprovalLoader, ConfirmLoader, InfoDisplayContainer } from "../component-styles"



const Swap: React.FC = () => {
  const [ unavailableFromList, setUnavailableFromList ] = useState<string[]>([])
  const [ unavailableToList, setUnavailableToList ] = useState<string[]>([])

  const [ tokenFrom, setTokenFrom ] = useState<TokenOnNetwork>(defaultToken)
  const [ tokenTo, setTokenTo ] = useState<TokenOnNetwork>(defaultToken)

  const [ amountIn, setAmountIn ] = useState<string | null>(null)
  const [ amountOut, setAmountOut ] = useState<string | null>(null)

  const [ bal, setBal ] = useState<BigNumber | null>(null)

  const [ reserves, setReserves ] = useState<Reserves>({ reserveA: ZERO, reserveB: ZERO })

  const [ gasPriceOptions, setGasPriceOptions ] = useState<GasFees>({ low: ZERO, medium: ZERO, high: ZERO })
  const [ txConfig, setTxConfig ] = useState<TxConfig>({ slippage: "0", gasPrice: "0", deadline: "0" })

  const [ exactField, setExactField ] = useState<ExactField | null>(null)

  const [ price, setPrice ] = useState<BigNumber | null>(null)
  const [ netSlippage, setNetSlippage ] = useState<BigNumber>(ZERO)
  const [ priceImpact, setPriceImpact ] = useState<PriceImpact>({ actualPrice: ZERO, percentage: "0" })

  const [ providerFee, setProviderFee ] = useState<BigNumber | null>(null)

  
  const [ status, setStatus ] = useState<Status | null>(null)

  const [ approvalLoading, setApprovalLoading ] = useState<boolean>(false)
  const [ confirmLoading, setConfirmLoading ] = useState<boolean>((false))

  const [ displayFromPicker, setDisplayFromPicker ] = useState<boolean>(false)
  const [ displayToPicker, setDisplayToPicker ] = useState<boolean>(false)
  const [ displayApproval, setDisplayApproval ] = useState<boolean>(false)
  const [ displayTxConfig, setDisplayTxConfig ] = useState<boolean>(false)
  const [ displayConfirm, setDisplayConfirm ] = useState<boolean>(false)



  const { provider, chainId, accounts } = useWeb3React()



  const swapFields = () => {
    const tokenASwap = tokenFrom
    const tokenBSwap = tokenTo
    handleTokenFromChange(tokenBSwap)
    handleTokenToChange(tokenASwap)
    handleAmountInChange("")
    handleAmountOutChange("")
  }

  const getGasOptions = useCallback(async () => {
    if(!provider) return
    const { low, medium, high } = await getGasFees(provider)
    setGasPriceOptions({ low, medium, high })
    setTxConfig({ slippage: "1", gasPrice: formatUnits(medium, Units.gwei), deadline: "5" })
  }, [ provider ])

  const refreshReserves = useCallback(async () => {
    if(!provider || !chainId) return
    if(displayFromPicker || displayToPicker) return
    if(tokenFrom.address && tokenTo.address && !status) {
      console.log(`Refreshing Swap Reserves. ${ tsToTime() }`)
      const currentReserves = await getReserves(provider, chainId, tokenFrom.address, tokenTo.address)
      if(!currentReserves.reserveA.isZero() && !currentReserves.reserveB.isZero()) {
        const currentPrice = getCurrentPrice(currentReserves, tokenFrom.decimals, tokenTo.decimals)
        setPrice(currentPrice)
      }
      setReserves(currentReserves)
    } else {
      setReserves({ reserveA: ZERO, reserveB: ZERO })
      setPrice(null)
    }
  }, [ provider, chainId, tokenFrom, tokenTo, status, displayToPicker, displayFromPicker ])

  const refreshBalance = useCallback(async () => {
    if(!provider || !accounts) return
    setAmountIn(null)
    setBal(null)
    if(tokenFrom.address) {
      const tokenABal = await getTokenBal(provider, accounts, tokenFrom.address)
      setBal(tokenABal)
    }
  }, [ provider, accounts, tokenFrom ])

  const checkAllowance = useCallback(async () => {
    if(!provider || !chainId || !accounts) return
    if(tokenFrom.address) {
      const needsApproval = await checkIfNeedsApproval(provider, chainId, accounts, tokenFrom.address)
      setDisplayApproval(needsApproval)
    } else {
      setDisplayApproval(false)
    }
  }, [ provider, chainId, accounts, tokenFrom ])

  const updateSwapInfo = useCallback(() => {
    if(tokenFrom.address && tokenTo.address && amountIn && amountOut && Number(amountIn) !== 0 && Number(amountOut) !== 0 && price && Number(price) !== 0 && !reserves.reserveA.isZero() && !reserves.reserveB.isZero() && (exactField === ExactField.From || exactField === ExactField.To)) {
      const amountInWei = parseUnits(amountIn, tokenFrom.decimals)
      const amountOutWei = parseUnits(amountOut, tokenTo.decimals)
      let uncertainAmount

      if(exactField === ExactField.From) {
        uncertainAmount = calculateNetSlippage(amountOutWei, txConfig.slippage, Net.Min)
      } else if(exactField === ExactField.To) {
        uncertainAmount = calculateNetSlippage(amountInWei, txConfig.slippage, Net.Max)
      } else {
        uncertainAmount = calculateNetSlippage(amountOutWei, txConfig.slippage, Net.Min)
      }

      const priceImpactInfo = calculatePriceImpact(price, amountInWei, amountOutWei, tokenFrom.decimals, tokenTo.decimals)
      setNetSlippage(uncertainAmount)
      setPriceImpact(priceImpactInfo)
    } else {
      setNetSlippage(ZERO)
      setPriceImpact({ actualPrice: ZERO, percentage: "0" })
    }
  }, [ tokenFrom, tokenTo, amountIn, amountOut, price, reserves, exactField, txConfig.slippage ])

  const checkConfirmActive = useCallback(() => {
    setDisplayConfirm(Boolean(tokenTo.address && tokenFrom.address && amountIn && amountOut && !netSlippage.isZero() && !netSlippage.isZero() && !displayApproval))
  }, [ tokenFrom, tokenTo, amountIn, amountOut, netSlippage, displayApproval ])



  const handleTokenFromChange = async (token: TokenOnNetwork) => {
    if(!provider || !chainId) return
    if(token.address) {
      setProviderFee(ZERO)
      setTokenFrom(token)
      const unavailableTokensTo = await getUnavailablePairsForToken(provider, chainId, token.address)
      setUnavailableToList(unavailableTokensTo)
    } else {
      setExactField(null)
      setTokenFrom(defaultToken)
      setUnavailableToList([])
    }
  }

  const handleTokenToChange = async (token: TokenOnNetwork) => {
    if(!provider || !chainId) return
    if(token.address) {
      setProviderFee(ZERO)
      setTokenTo(token)
      const unavailableTokensFrom = await getUnavailablePairsForToken(provider, chainId, token.address)
      setUnavailableFromList(unavailableTokensFrom)
    } else {
      setExactField(null)
      setTokenTo(defaultToken)
      setUnavailableFromList([])
    }
  }

  const handlePickerFromClick = () => {
    if(!displayFromPicker) setDisplayFromPicker(true)
  }

  const handlePickerToClick = () => {
    if(!displayToPicker) setDisplayToPicker(true)
  }

  const handleApproveClick = async () => {
    if(!provider || !chainId) return
    if(!tokenFrom.address) return
    try {
      setApprovalLoading(true)
      await approveRouterAll(provider, chainId, tokenFrom.address)
      setApprovalLoading(false)
      await checkAllowance()
    } catch(err: any) {
      setStatus({ msg: err.message, code: StatusCode.Fail })
      setApprovalLoading(false)
      setDisplayApproval(true)
    }
  }

  const handleAmountInChange = async (amount: string) => { 
    if(!provider || !chainId) return 
    const isShortEnough = checkIsShortEnough(amount)

    if(isShortEnough) {
      if(amount) {
        setExactField(ExactField.From)
        setAmountIn(amount)
        if(tokenFrom.address && tokenTo.address && amount && (Number(amount) !== 0)) {
          const amountInWei = parseUnits(amount, tokenFrom.decimals)
          const amountOutEquivWei = await getAmountOut(provider, chainId, amountInWei, tokenFrom.address, tokenTo.address, reserves)
          const amountOutEquiv = formatUnits(amountOutEquivWei, tokenTo.decimals)
          const feeWei = calculateProviderFee(amountInWei, ExactField.From)
          setAmountOut(amountOutEquiv)
          setProviderFee(feeWei)
        } else {
          setExactField(null)
          setAmountOut(null)
          setProviderFee(ZERO)
        }
      } else {
        setExactField(null)
        setAmountIn(null)
        setAmountOut(null)
      }
    }
  }

  const handleAmountOutChange = async (amount: string) => { 
    if(!provider || !chainId) return
    const isShortEnough = checkIsShortEnough(amount)

    if(isShortEnough) {
      if(amount) {
        setExactField(ExactField.To)
        setAmountOut(amount)
        if(tokenFrom.address && tokenTo.address && amount && (Number(amount) !== 0)) {
          const amountOutWei = parseUnits(amount, tokenTo.decimals)
          const amountInEquivWei = await getAmountIn(provider, chainId, amountOutWei, tokenFrom.address, tokenTo.address, reserves)
          const amountInEquiv = formatUnits(amountInEquivWei, tokenFrom.decimals)
          const feeWei = calculateProviderFee(amountInEquivWei, ExactField.To)
          setAmountIn(amountInEquiv)
          setProviderFee(feeWei)
        } else {
          setExactField(null)
          setAmountIn(null)
          setProviderFee(ZERO)
        }
      } else {
        setExactField(null)
        setAmountIn(null)
        setAmountOut(null)
      }
    }
  }

  const handleMaxClick = () => {
    if(!chainId) return
    if(!bal) {
      setAmountIn(null)
      return
    }
    const max = deductGasFee(chainId, tokenFrom.address, bal)
    const maxPositive = max.lt(ZERO) ? ZERO : max
    const balString = formatUnits(maxPositive, tokenFrom.decimals)
    handleAmountInChange(balString)
  }

  const handleSettingsClick = () => {
    setDisplayTxConfig(true)
  }

  const handleConfirmClick = async () => {
    if(!provider || !chainId || !accounts) return
    if(displayConfirm && amountIn && amountOut && !displayApproval && (exactField === ExactField.From || exactField === ExactField.To)) {
      const amountInWei = parseUnits(amountIn, tokenFrom.decimals)
      const amountOutWei = parseUnits(amountOut, tokenTo.decimals)

      if(bal && amountInWei.gt(bal)) {
        setStatus({ msg: `Amount ${ tokenFrom.symbol } exceeds balance.`, code: StatusCode.Warn })
        setDisplayConfirm(true)
      } else if(amountOutWei.gt(reserves.reserveB)) {
        setStatus({ msg: `Insufficient ${ tokenTo.symbol } in this pair.`, code: StatusCode.Fail })
        setDisplayConfirm(true)
      } else {
        setConfirmLoading(true)
        setDisplayConfirm(false)
        try {
          const tx = await swap(provider, chainId, accounts, tokenFrom.address, tokenTo.address, amountInWei, amountOutWei, txConfig, netSlippage, exactField)
          const { returnValues, receipt } = tx ? tx : { returnValues: "", receipt:  "" }
          const [ amountTransferred, amountReceived ] = returnValues && returnValues.length ? returnValues : [ ZERO, ZERO ]
          const txResult = (!amountTransferred.isZero() && !amountReceived.isZero()
            ? { msg: `Swapped ${ significantDigits(formatUnits(amountTransferred, tokenFrom.decimals), 8) } ${ tokenFrom.symbol } for ${ significantDigits(formatUnits(amountReceived, tokenTo.decimals), 8) } ${ tokenTo.symbol }`, code: StatusCode.Success, tx: receipt.transactionHash }
            : null
          )

          setConfirmLoading(false)
          setAmountIn(null)
          setAmountOut(null)
          setStatus(txResult)
          refreshBalance()
        } catch(err: any) {
          setStatus({ msg: err.message, code: StatusCode.Fail })
          setConfirmLoading(false)
          setDisplayConfirm(true)
        }
      }
    }
  }



  useEffect(() => {
    getGasOptions()
  }, [ getGasOptions ])

  useEffect(() => {
    refreshReserves()
    const refreshInterval = setInterval(() => refreshReserves(), 15000)
    return () => clearInterval(refreshInterval)
  }, [ refreshReserves ])

  useEffect(() => {
    refreshBalance()
    checkAllowance()
  }, [ refreshBalance, checkAllowance ])

  useEffect(() => {
    const updateTimeout = setTimeout(() => updateSwapInfo(), 1000)
    return () => clearTimeout(updateTimeout)
  }, [ updateSwapInfo ])

  useEffect(() => {
    checkConfirmActive()
  }, [ checkConfirmActive ])



  return (
    <>
      <Title theme={ Theme }>SWAP</Title>

      <BalanceRow>
        <ApproveButton onClick={ () => !approvalLoading && !confirmLoading ? handleApproveClick() : null } theme={ Theme } isActive={ displayApproval }>{ !approvalLoading ? "APPROVE" : <><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/><ApprovalLoader theme={ Theme }/></> }</ApproveButton>
        <TokenBalance onClick={ handleMaxClick } theme={ Theme } isActive={ Boolean(bal) }>{ bal && bal.isZero() ? "0.0" : significantDigits(formatUnits(bal, tokenFrom.decimals), 8) }</TokenBalance>
      </BalanceRow>

      <MainRow isBottom={ false }>
        <InputToken onClick={ handlePickerFromClick } theme={ Theme } isSelected={ Boolean(tokenFrom.address) }><Icon src={ tokenFrom.logo } size={ "30" }/>{ tokenFrom.symbol }<DropDownIcon/></InputToken>
        <InputAmount type="number" step="any"  min="0" placeholder={ "0.0" } value={ amountIn ? amountIn : "" } onChange={ e => handleAmountInChange(e.target.value) } theme={ Theme }/>
      </MainRow>


      <SwitchFields onClick={ swapFields } theme={ Theme }><DropDownIcon/></SwitchFields>

      <MainRow isBottom={ false }>
        <InputToken onClick={ handlePickerToClick } theme={ Theme } isSelected={ Boolean(tokenTo.address) }><Icon src={ tokenTo.logo } size={ "30" }/>{ tokenTo.symbol }<DropDownIcon/></InputToken>
        <InputAmount type="number" step="any" min="0" placeholder={ "0.0" } value={ amountOut ? amountOut : "" } onChange={ e => handleAmountOutChange(e.target.value) } theme={ Theme }/>
      </MainRow>

      <BottomRow>
        <SettingsSpacer>
          <SettingsButton onClick={ handleSettingsClick } theme={ Theme }><SettingsIcon/></SettingsButton>
        </SettingsSpacer>
        <ConfirmSpacer>
          <ConfirmButton onClick={ () => !approvalLoading && !confirmLoading ? handleConfirmClick() : null } theme={ Theme } isActive={ Boolean(displayConfirm && !confirmLoading) }>{ !confirmLoading ? "Swap" : <><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/></> }</ConfirmButton>
        </ConfirmSpacer>
      </BottomRow>

      <InfoDisplay theme={ Theme } isActive={ Boolean(price && !price.isZero()) }>
        <InfoDisplayContainer theme={ Theme }>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Quote Price per { tokenTo.symbol }</InfoDisplayKey><InfoDisplayData>{ price ? significantDigits(formatUnits(price, Units.ether), 8) : "0000.0000" } { tokenFrom.symbol }</InfoDisplayData></InfoDisplayRow>
          { !priceImpact.actualPrice.isZero() ? <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Actual Price per { tokenTo.symbol }</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(priceImpact.actualPrice, Units.ether), 8) } { tokenFrom.symbol }</InfoDisplayData></InfoDisplayRow> : null }
          { !priceImpact.actualPrice.isZero() ? <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Price Impact</InfoDisplayKey><InfoDisplayData>{ (Number(priceImpact.percentage)).toFixed(2) }%</InfoDisplayData></InfoDisplayRow> : null }
        </InfoDisplayContainer>
      </InfoDisplay>

      <InfoDisplay theme={ Theme } isActive={ Boolean((exactField === ExactField.To || exactField === ExactField.From) && !priceImpact.actualPrice.isZero()) }>
        {
          exactField === ExactField.To
            ? <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Maximum spent</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(netSlippage, tokenFrom.decimals), 8) } { tokenFrom.symbol }</InfoDisplayData></InfoDisplayRow>
            : <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Minimum received</InfoDisplayKey><InfoDisplayData>{ significantDigits(formatUnits(netSlippage, tokenTo.decimals), 8) } { tokenTo.symbol }</InfoDisplayData></InfoDisplayRow>
        }
      </InfoDisplay>

      <InfoDisplay theme={ Theme } isActive={ Boolean(providerFee && !providerFee.isZero()) }>
        <InfoDisplayContainer theme={ Theme }>
          <InfoDisplayRow theme={ Theme }><InfoDisplayKey>Provider Fee</InfoDisplayKey><InfoDisplayData>{ providerFee && !providerFee.isZero() ? significantDigits(formatUnits(providerFee, tokenFrom.decimals), 8) : "0.0"  } { tokenFrom.symbol }</InfoDisplayData></InfoDisplayRow>
        </InfoDisplayContainer>
      </InfoDisplay>

      {
        displayFromPicker && chainId
          ? <Picker onClose={ () => setDisplayFromPicker(false) } unavailable={ hideIdenticalTokens(chainId, tokenTo.address, unavailableFromList) } setPick={ handleTokenFromChange } title="Select Token From"/>
          : ""
      }

      {
        displayToPicker && chainId
          ? <Picker onClose={ () => setDisplayToPicker(false) } unavailable={ hideIdenticalTokens(chainId, tokenFrom.address, unavailableToList) } setPick={ handleTokenToChange } title="Select Token To"/>
          : ""
      }

      {
        displayTxConfig 
          ? <TxConfigure onClose={ () => setDisplayTxConfig(false) } txConfig={ txConfig } setTxConfig={ setTxConfig } gasPriceOptions={ gasPriceOptions }/>
          : ""
      }

      {
        status && status.msg
          ? <StatusMessage onClose={ () => setStatus(null) } status={ status }/>
          : ""
      }

    </>
  )
}

export default Swap
