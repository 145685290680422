
import { useEffect, useRef, useCallback } from "react"
import ReactDOM from "react-dom"

import { Status, StatusCode } from "../utils/web3Utils"

import { Theme } from "../theme"
import { InfoIcon, ErrorIcon, WarningIcon, CloseIcon, ArrowIcon, ModalOverlay, ModalClose, StatusPopUp, StatusContent, StatusClose, StatusSymbol, StatusError, StatusWarning, StatusSuccess, StatusLink } from "../component-styles"


interface Props {
  onClose: () => void,
  status: Status
}

const StatusMessage: React.FC<Props> = ({ onClose, status }) => {
  const overlay = useRef<HTMLDivElement>(null)
  const modal = useRef<HTMLDivElement>(null)



  const exitModal = useCallback((e: MouseEvent) => {
    if(overlay?.current?.contains(e.target as Node) && !modal?.current?.contains(e.target as Node)) {
      onClose()
    }
  }, [ onClose ])



  useEffect(() => {
    document.addEventListener("mousedown", exitModal)

    return () => {
      document.removeEventListener("mousedown", exitModal)
    }
  }, [ exitModal ])



  const displayLink = () => {
    if(status.tx) {
      const link = `https://fsnscan.com/tx/${ status.tx }`

      return (
        <StatusLink href={ link } theme={ Theme } target="_blank">
          <ArrowIcon/>
        </StatusLink>
      )
    }
  }

  const displayStatusMessage = () => {
    if(status.code === StatusCode.Fail) {
      return <StatusError theme={ Theme }><StatusSymbol><ErrorIcon/></StatusSymbol>{ status.msg }<StatusSymbol>{ displayLink() }</StatusSymbol></StatusError>
    } else if(status.code === StatusCode.Warn) {
      return <StatusWarning theme={ Theme }><StatusSymbol><WarningIcon/></StatusSymbol>{ status.msg }<StatusSymbol>{ displayLink() }</StatusSymbol></StatusWarning>
    } else if(status.code === StatusCode.Success) {
      return <StatusSuccess theme={ Theme }><StatusSymbol><InfoIcon/></StatusSymbol>{ status.msg }<StatusSymbol>{ displayLink() }</StatusSymbol></StatusSuccess>
    }
  }



  return ReactDOM.createPortal(
    <ModalOverlay ref={ overlay } theme={ Theme }>
      <StatusPopUp ref={ modal } theme={ Theme }>
        <StatusContent>
          {
            displayStatusMessage()
          }
        </StatusContent>
        <StatusClose onClick={ () => onClose() }>
          <ModalClose theme={ Theme }><CloseIcon/></ModalClose>
        </StatusClose>
     </StatusPopUp>
    </ModalOverlay>,
    document.getElementById("status")!
  )
}

export default StatusMessage
