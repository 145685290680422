
interface Token {
  name: string,
  symbol: string,
  address: string,
  futures: string[],
  options: string[],
  decimals: {
    spot: number,
    futures: number[],
    options: number[]
  },
  logo: string
}

interface TokenOnNetwork {
  name: string,
  symbol: string,
  address: string,
  decimals: number,
  logo: string
}

interface Pair {
  tokenA: TokenOnNetwork,
  tokenB: TokenOnNetwork
}

const defaultToken: TokenOnNetwork = {
  name: "",
  symbol: "Select",
  address: "",
  decimals: 0,
  logo: require("../images/placeholder.png")
}

const ZERO_ADDR = "0x0000000000000000000000000000000000000000"
// const LP_DECIMALS = 4
const LP_DECIMALS = 18


export { defaultToken, ZERO_ADDR, LP_DECIMALS }
export type { Token, TokenOnNetwork, Pair }
